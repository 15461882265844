<template>
  <div v-loading="loading">
    <el-card>
      <el-date-picker 
        v-model="date"
        :placeholder="$t('chart.date.start')"
        :picker-options="datePickerOptions"
        type="datetime"
        format="yyyy/MM/dd HH:mm"
        @change="changeDate"
      />
      <el-button v-if="isSelectInstrumentType && !isContinuityType" @click="uploadOutput">
        上傳資料
      </el-button>
      <el-button v-if="isSelectInstrumentType && isContinuityType && !roading" @click="insGroupUpload">
        上傳資料
      </el-button>
      <el-select v-model="instrumentType" placeholder="請選擇儀器類型" @change="changeInstrumentType">
        <el-option
          v-for="item in instrumentTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>

      <el-cascader
        v-model="selectedInstrumentList"
        style="min-width:200px"
        :options="instrumentOptions"
        :props="props"
        :collapse-tags="true"
        :show-all-levels="false"
        :placeholder="$t('chart.instrument.pick')"
        @change="getCascaderInstrumentList"
      ></el-cascader>
    </el-card>
    <el-card v-if="isSelectInstrumentType && !isContinuityType">
      <hot-table id="hot-container" ref="hot" root="hot" :settings="settings" />
    </el-card>
    <instrument-group-edit
      v-if="isSelectInstrumentType && isContinuityType && !loading"
      :outputList="outputList"
      :getInstrumentType="getInstrumentType"
      :date="date"
      ref="insGroupEdit"
    >
    </instrument-group-edit>
  </div>
</template>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import { HotTable } from '@handsontable/vue';
import InstrumentGroupEdit from '@/components/manual/InstrumentGroupEdit.vue';

MomentTimeZone();
export default {
  components: {
    HotTable,
    InstrumentGroupEdit,
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    settings() {
      const vm = this;
      return {
        data: vm.outputList,
        fixedColumnsLeft: 1,
        colHeaders: vm.columnsHeader,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        columns: vm.columns,
        licenseKey: 'non-commercial-and-evaluation',

        beforeChange(changes, source) {
          // [[row, prop, oldVal, newVal], ...]
          for(let change of changes){
            let row = change[0];
            vm.outputList[row][change[1]] = Number(change[3]);
            if(change[1] === 'reading' && !isNaN(Number(change[3])))
            {
              let instrument = vm.instrumentList.find(ele => ele.name === vm.outputList[row].instrumentName);
              vm.outputList[row].result = vm.computedOutput(vm.outputList[row], instrument).result;
            }
            if(change[1] === 'A+' && !isNaN(Number(change[3])) && !isNaN(Number(vm.outputList[row]['A-'])))
            {
              console.log(`${change[3]} ${vm.outputList[row]['A-']}`);
              let instrument = vm.instrumentList.find(ele => ele.name === vm.outputList[row].instrumentName);
              vm.outputList[row].result = vm.computedOutput(vm.outputList[row], instrument).result;
            }
            if(change[1] === 'A-' && !isNaN(Number(change[3])) && !isNaN(Number(vm.outputList[row]['A+'])))
            {
              console.log(`${change[3]} ${vm.outputList[row]['A+']}`);
              let instrument = vm.instrumentList.find(ele => ele.name === vm.outputList[row].instrumentName);
              vm.outputList[row].result = vm.computedOutput(vm.outputList[row], instrument).result;
            }
          }
        },
      };
    },
    isSelectInstrumentType(){
      return this.getInstrumentType;
    },
    isContinuityType() {
      return this.instrumentType.includes('SIS');
    },
    getInstrumentType(){
      return this.instrumentTypeList.find(ele=>{
        return ele.name === this.instrumentType;
      });
    },
    columns() {
      let constCol = this.constCol;
      let insTypeObjKey = Object.keys(this.getInstrumentType);
      if( insTypeObjKey.includes('A+') && insTypeObjKey.includes('A-') && this.getInstrumentType['A+'] && this.getInstrumentType['A-'] )
      {
        constCol = this.constCol.filter(ele=>{
          return ele!='reading' && ele!='R0';
        });
      }
      return  this.col.filter(ele => {
        let insTypeObjKey = Object.keys(this.getInstrumentType);
        return (insTypeObjKey.includes(ele.data) && this.getInstrumentType[ele.data]) || constCol.includes(ele.data);
      });
    },
    columnsHeader() {
      let constCol = this.constCol;
      let insTypeObjKey = Object.keys(this.getInstrumentType);
      if( insTypeObjKey.includes('A+') && insTypeObjKey.includes('A-') && this.getInstrumentType['A+'] && this.getInstrumentType['A-'] )
      {
        constCol = this.constCol.filter(ele=>{
          return ele!='reading' && ele!='R0';
        });
      }
      let col = this.col.map((ele, index)=>{
        return {
          data: ele.data,
          index,
        };
      }).filter(ele => {
        let insTypeObjKey = Object.keys(this.getInstrumentType);
        return (insTypeObjKey.includes(ele.data) && this.getInstrumentType[ele.data]) || constCol.includes(ele.data);
      });
      return this.colHeader.filter((ele, index)=>{
        return col.findIndex(c=> c.index == index) >= 0;
      });
    },
  },
  data(){
    return {
      constCol: [
        'instrumentName',
        'unit',
        'R0',
        'F1',
        'reading',
        'result',
        'lastOutput',
        'diff',
        'WL',
        'AL',
        'WSL',
        'remark',
      ],
      col: [
        {
          data: 'instrumentName',
          readOnly: true,
        },
        {
          data: 'unit',
          readOnly: true,
        },
        {
          data: 'R0',
          readOnly: true,
        },
        {
          data: 'A+initial',
          readOnly: true,
        },
        {
          data: 'A-initial',
          readOnly: true,
        },
        {
          data: 'F1',
          readOnly: true,
        },
        {
          data: 'reading',
        },
        {
          data: 'A+',
        },
        {
          data: 'A-',
        },
        {
          data: 'result',
          readOnly: true,
        },
        {
          data: 'lastOutput',
          readOnly: true,
        },
        {
          data: 'diff',
          readOnly: true,
        },
        {
          data: 'WL',
          readOnly: true,
        },
        {
          data: 'AL',
          readOnly: true,
        },
        {
          data: 'WSL',
          readOnly: true,
        },
        {
          data: 'remark',
          editor: 'select',
          selectOptions: ['', '阻礙', '損壞', '無法進入', '不合格', '暫停量測', '不明原因', '碰撞', '重新安裝'],
        },
      ],
      colHeader: [
        '儀器名稱',
        '單位',
        '初值',
        '正向初值',
        '負向初值',
        'F1',
        '讀值',
        'A+',
        'A-',
        '變化量',
        '前次變化量',
        '差值',
        '注意值',
        '警戒值',
        '行動值',
        '備註',
      ],
      outputList: [],
      originInstrumentList: [],
      commonTypeInstrumentList: [],
      instrumentList: [],
      date: moment().startOf('day'),
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      instrumentTypeList: [],
      instrumentType: '',

      instrumentOptions: [],
      selectedInstrumentList: [],
      props: {
        multiple: true,
        //expandTrigger: 'hover',
        label: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      instrumentLoading: false,
      typeLoading: false,
      outputLoading: false,
    };
  },
  watch: {
    instrumentLoading() {
      this.loading = this.instrumentLoading || this.typeLoading || this.outputLoading;
    },
    typeLoading() {
      this.loading = this.instrumentLoading || this.typeLoading || this.outputLoading;
    },
    outputLoading() {
      this.loading = this.instrumentLoading || this.typeLoading || this.outputLoading;
    },
  },
  async mounted(){
    this.getInstrumentTypeList();
    await this.getInstruments();
    //await this.getOutput();
  },
  methods: {
    async getInstrumentTypeList(){
      this.typeLoading = true;
      const res = await this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'instrument_type',
      });
      this.instrumentTypeList = res.data.filter(ele => ele.manual).map(ele=>{
        return {
          ...ele,
          value: ele.name,
          label: ele.name,
        };
      }).sort((a,b) => a.value[0].localeCompare(b.value[0]));
      this.typeLoading = false;
    },
    async getInstruments(){
      this.instrumentLoading = true;
      const res = await this.$axios({
        category: 'manual',
        method: 'get',
        apiName: `getManualInstrument/${this.selectedProject._id}`,
      });
      this.originInstrumentList = res.data;
      this.getInstrumentsData();
      this.initCascaderSelector();
      this.instrumentLoading = false;
    },
    getInstrumentsData(){
      this.outputList = [];
      let instrumentList = this.commonTypeInstrumentList.filter(ins => {
        if(this.selectedInstrumentList.length == 0) return true;
        else return this.selectedInstrumentList.findIndex((ele) => ins.data[0].name === ele[2]) >= 0;
      }).map(ins => {
        let index = this.getDataByTime(ins, this.date);
        let data = ins.data[index];

        let WL_plus = ![null,NaN,undefined].includes(data ? data.WL_plus : undefined);
        let WL_minus = ![null,NaN,undefined].includes(data ? data.WL_minus : undefined);
        let AL_plus = ![null,NaN,undefined].includes(data ? data.AL_plus : undefined);
        let AL_minus = ![null,NaN,undefined].includes(data ? data.AL_minus : undefined);
        let WSL_plus = ![null,NaN,undefined].includes(data ? data.WSL_plus : undefined);
        let WSL_minus = ![null,NaN,undefined].includes(data ? data.WSL_minus : undefined);

        let WL = '';
        if(WL_plus && WL_minus) WL = `${data.WL_plus}/${data.WL_minus}`;
        else if(WL_plus) WL = `${data.WL_plus}`;
        else if(WL_minus) WL = `${data.WL_minus}`;
        let AL = '';
        if(AL_plus && AL_minus) AL = `${data.AL_plus}/${data.AL_minus}`;
        else if(AL_plus) AL = `${data.AL_plus}`;
        else if(AL_minus) AL = `${data.AL_minus}`;
        let WSL = '';
        if(WSL_plus && WSL_minus) WSL = `${data.WSL_plus}/${data.WSL_minus}`;
        else if(WSL_plus) WSL = `${data.WSL_plus}`;
        else if(WSL_minus) WSL = `${data.WSL_minus}`;
        return {
          _id: ins._id,
          name: ins.data[0].name,
          unit: ins.data[0].unit,
          area: ins.area,
          station: ins.station,
          R0: data ? data.R0 : '',
          'A+initial': data ? data['A+'] : '',
          'A-initial': data ? data['A-'] : '',
          offset: data ? data.offset : '',
          T0: data ? data.T0 : '',
          K : data ? data.K : '',
          F0: data ? data.F0 : '',
          F1: data ? data.F1 : '',
          F2: data ? data.F2 : '',
          F3: data ? data.F3 : '',
          F4: data ? data.F4 : '',
          F5: data ? data.F5 : '',

          WL,
          AL,
          WSL,
          startDate: data ? data.startDate : '',
        };
      }).filter(ins => {
        return ins.R0 !== '';
      });
      instrumentList = instrumentList.sort((a, b)=>{
        return a.name - b.name;
      });
      
      for(let instrument of instrumentList){
        this.outputList.push({
          instrumentName: instrument.name,
          R0: instrument.R0,
        });
      }
      this.instrumentList = instrumentList;
    },
    getDataByTime(instrument, time){
      let index = -1;
      let datas = instrument.data.sort((a, b)=>{
        return a.startDate - b.startDate;
      });
      for(let data of datas){
        index++;
        if(moment(data.startDate) > time) {
          index--;
          break;
        }
      }
      return index;
    },
    initCascaderSelector() {
      this.instrumentOptions = [];
      this.selectedInstrumentList = [];

      let areas = {};

      this.originInstrumentList.forEach(ins=>{
        if(areas[ins.area]){
          areas[ins.area].push(ins);
        }
        else areas[ins.area] = [ins];
      });

      let instrumentOptions = [];

      for(let key of Object.keys(areas)){
        areas[key] = areas[key].reduce((acc,cur)=>{
          let index = acc.stations.findIndex(ele => ele.station === cur.station);
          if(index < 0){
            acc.stations.push({
              station: cur.station,
              instruments: [cur],
            });
          }
          else{
            acc.stations[index].instruments.push(cur);
          }
          return acc;
        },{ stations: []});

        instrumentOptions.push({
          label: key,
          //disabled: areas[key].stations.length === 0,
          children: areas[key].stations.map((station)=>{
            return {
              label: station.station,
              //disabled: station.instruments.length === 0,
              children: station.instruments.map(ins=>{
                return {
                  label: ins.data[0].name,
                  value: ins.data[0].name,
                };
              }),
            };
          }),
        });
      }

      this.instrumentOptions = instrumentOptions;
    },
    async getOutput(){
      this.outputLoading = true;
      const res = await this.$axios({
        category: 'manual',
        method: 'get',
        apiName: `getAllManualInstrumentOutput/${this.selectedProject._id}/${this.date}`,
      });
      this.outputList = this.outputList.map(ele=>{
        let output = res.data.find(output=> output.name === ele.instrumentName);
        let instrument = this.instrumentList.find(ins=> ins.name === ele.instrumentName);
        if(!output || [NaN, null, undefined].includes(output.reading2)) 
          return {
            ...ele,
            ...instrument,
            lastOutput: output.lastOutput,
          };
        return {
          ...ele,
          ...instrument,
          ...this.computedOutput(output, instrument),
          lastOutput: output.lastOutput,
        };
      });
      //console.log(this.outputList);
      this.outputLoading = false;
      this.updateTable();
    },
    computedOutput(output, instrument){
      let reading1 = output.reading ? output.reading : 0;
      let A_plus = output['A+'] ? output['A+'] : 0;
      let A_minus = output['A-'] ? output['A-'] : 0;
      let A_plusInitial = instrument['A+initial'] ? instrument['A+initial'] : 0;
      let A_minusInitial = instrument['A-initial'] ? instrument['A-initial'] : 0;
      //  (F5(R1)^5＋F4(R1)^4＋F3(R1)^3＋F2(R1)^2+F1(R1)+F0 ) - (F5(R0)^5＋F4(R0)^4＋F3(R0)^3＋F2(R0)^2+F1(R0)+F0 )+offset
      let front =
        instrument.F5 * Math.pow(reading1, 5) +
        instrument.F4 * Math.pow(reading1, 4) +
        instrument.F3 * Math.pow(reading1, 3) +
        instrument.F2 * Math.pow(reading1, 2) +
        instrument.F1 * reading1 +
        instrument.F0 +
        instrument.F1 * A_plus -
        instrument.F1 * A_minus ;
      let rear =
        instrument.F5 * Math.pow(instrument.R0, 5) +
        instrument.F4 * Math.pow(instrument.R0, 4) +
        instrument.F3 * Math.pow(instrument.R0, 3) +
        instrument.F2 * Math.pow(instrument.R0, 2) +
        instrument.F1 * instrument.R0 +
        instrument.F0 +
        instrument.F1 * A_plusInitial -
        instrument.F1 * A_minusInitial ;
      let reading2 = (front - rear + instrument.offset).toFixed(2);
      return {
        reading: reading1,
        'A+': A_plus,
        'A-': A_minus,
        result : reading2,
        diff: (![undefined, NaN, null].includes(output.lastOutput) && ![undefined, NaN, null].includes(reading2)) ? Number(reading2 - output.lastOutput).toFixed(4) : '',
        remark: output.remark,
      };
    },
    uploadOutput(){
      let outputList = this.outputList.filter(output=>{
        let isnull = Object.keys(output).length == 0 || Object.values(output).reduce((accumulator, currentValue) => accumulator && [''].includes(currentValue),true);
        return !isnull;
      });
      //console.log(outputList);
      this.outputLoading = true;
      this.$axios({
        category: 'manual',
        method: 'put',
        apiName: 'addManualOutput',
        data: {
          outputs: outputList,
          date: this.date,
          project: this.selectedProject._id,
        },
      }).then(()=>{
        this.$notifySuccess('新增成功');
        this.outputLoading = false;
        this.updateTable();
      });
    },
    insGroupUpload(){
      this.$refs.insGroupEdit.uploadOutput();
    },
    changeDate(){ 
      if(!this.getInstrumentType) return;
      this.getInstrumentsData();
      this.getOutput();
    },
    changeInstrumentType(){
      //console.log(this.getInstrumentType);
      if(!this.getInstrumentType) return;
      
      this.commonTypeInstrumentList = this.originInstrumentList.filter(ele=> {
        //console.log(ele.instrument_type);
        return ele.instrument_type._id == this.getInstrumentType._id;
      });
      this.getInstrumentsData();
      this.getOutput();
    },
    getCascaderInstrumentList(){
      //console.log(this.selectedInstrumentList);
      this.getInstrumentsData();
      this.getOutput();
    },
    updateTable() {
      if(this.isSelectInstrumentType && !this.isContinuityType)
        setTimeout(() => {
          this.$refs.hot.hotInstance.loadData(this.outputList);
        }, 100);
    },
  },
};
</script>
  
<style>
</style>