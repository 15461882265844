<template lang="html">
  <el-menu :default-active="$route.path" :router="true" class="sidebar-container" mode="vertical" :collapse="!isCollapse">
      <div class="sidebar-collapse-switch">
        <el-switch
        v-model="isCollapse"
        @change="collapse"
      >
        </el-switch>
      </div>
      <div
        v-for="child in getMenuList"
        :key="child.path"
      >
        <el-menu-item
          v-if="child.type!='sub'"
          :class="$route.path === child.path ? 'is-active' : ''"
          :index="child.path"
        >
          <template>
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon :icon="child.icon" />
            </font-awesome-layers>
            <span class="route-name">{{ $t(`sidebar.${child.name}`) }}</span>
          </template>
        </el-menu-item>
        <el-submenu :index="child.menuIndex" v-else>
          <div slot="title">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon :icon="child.icon" />
            </font-awesome-layers>
            <span class="route-name">{{ $t(`sidebar.${child.name}`) }}</span>
          </div>
          <el-menu-item
            v-for="listchild in child.menuList"
            :index="listchild.path"
            :class="$route.path === listchild.path ? 'is-active' : ''"
            :key="listchild.path"
          >
            <template>
              <font-awesome-layers class="fa-lg">
                <font-awesome-icon :icon="listchild.icon" />
              </font-awesome-layers>
              <span class="route-name">{{ $t(`sidebar.${listchild.name}`) }}</span>
            </template>
          </el-menu-item>
        </el-submenu>
      </div>
      <el-submenu v-if="isAdmin" index="3">
        <div slot="title">
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon icon="user-cog" />
          </font-awesome-layers>
          <span class="route-name">Admin</span>
        </div>
        <el-menu-item-group v-for="group in adminMenuList" :key="group.label">
          <span slot="title" class="group-name">{{ group.label }}</span>
          <el-menu-item v-for="child in group.routes" :key="child.path" :index="child.path">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon :icon="child.icon" />
            </font-awesome-layers>
            <span class="route-name">{{ child.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
  </el-menu>
</template>

<script>
import { sidebar, clientVersion } from '@/constants/env';
const role = clientVersion === 'test' || clientVersion === 'kmrt';
export default {
  //props: {
  //  isCollapse: {dmin
  
  //    type: boolean,
  //    require: true,
  //  },
  //},
  data() {
    return {
      isCollapse: true,
      indexCounter: 0,
      menuList: [
        {
          path: '/dashboard',
          name: 'dashboard',
          icon: 'tachometer-alt',
        },
        //storm
        {
          name: 'storm',
          type: 'sub',
          icon: 'cloud-showers-heavy',
          menuIndex: '1',
          menuList: [
            {
              path: '/storm/summary',
              name: 'summary',
              icon: 'table',
            },
            {
              path: '/storm/data',
              name: 'data',
              icon: 'table',
            },
            {
              path: '/storm/chart',
              name: 'chart',
              icon: 'chart-line',
            },
            {
              path: '/storm/snake-line',
              name: 'snakeLine',
              icon: 'chart-line',
            },
            {
              path: '/storm/export',
              name: 'export',
              icon: 'file-excel',
            },
            /*{
              name: 'Admin',
              type: 'sub',
              icon: 'cloud-showers-heavy',
              menuList: [
                {
                  type: 'labelList',
                  label: '設定',
                  menuList: [
                    {
                      path: '/storm/admin/instrument',
                      name: '儀器',
                      icon: 'table',
                    },
                    {
                      path: '/storm/admin/instrument-group',
                      name: '儀器組',
                      icon: 'table',
                    },
                    {
                      path: '/storm/admin/instrumentComputer',
                      name: '儀器公式',
                      icon: 'table',
                    },
                    {
                      path: '/storm/admin/project',
                      name: '專案',
                      icon: 'tasks',
                    },
                    {
                      path: '/storm/admin/area',
                      name: '區域',
                      icon: 'tasks',
                    },
                    {
                      path: '/storm/admin/station',
                      name: '測站',
                      icon: 'tasks',
                    },
                    {
                      path: '/storm/admin/client',
                      name: '客戶',
                      icon: 'tasks',
                    },
                    {
                      path: '/storm/admin/layout',
                      name: '燈號',
                      icon: 'table',
                    },
                    {
                      path: '/storm/admin/remark',
                      name: '備註',
                      icon: 'table',
                    },
                    {
                      path: '/storm/admin/document',
                      name: '檔案',
                      icon: 'table',
                    },
                    {
                      path: '/storm/admin/sms',
                      name: '簡訊',
                      icon: 'sms',
                    },
                    {
                      path: '/storm/admin/log',
                      name: '登入記錄',
                      icon: 'user',
                    },
                  ],
                },
                {
                  type: 'labelList',
                  label: '數據修改',
                  menuList: [
                    {
                      path: '/storm/admin/edit/output',
                      name: 'Output',
                      icon: 'file-excel',
                    },
                    {
                      path: '/storm/admin/edit/instrument',
                      name: 'Instrument',
                      icon: 'file-excel',
                    },
                  ],
                },
              ],
            },*/
          ],
        },
        //slope
        {
          name: 'slope',
          type: 'sub',
          icon: 'mountain',
          menuIndex: '0',
          menuList: [
            {
              path: '/slope/data',
              name: 'data',
              icon: 'table',
            },
            {
              path: '/slope/chart',
              name: 'chart',
              icon: 'chart-line',
            },
            {
              path: '/slope/curve',
              name: 'curve',
              icon: 'chart-line',
            },
            {
              path: '/slope/map',
              name: 'map',
              icon: 'map-marked-alt',
            },
            {
              path: '/slope/layout',
              name: 'layout',
              icon: 'info-circle',
            },
            {
              path: '/slope/export',
              name: 'export',
              icon: 'file-excel',
            },
          ],
        },
        {
          path: '/summary',
          name: 'summary',
          icon: 'table',
        },
        {
          path: '/data',
          name: 'data',
          icon: 'table',
        },
        {
          path: '/chart',
          name: 'chart',
          icon: 'chart-line',
        },
        {
          path: '/snake-line',
          name: 'snakeLine',
          icon: 'chart-line',
        },
        {
          path: '/curve',
          name: 'curve',
          icon: 'chart-line',
        },
        {
          name: 'manual',
          type: 'sub',
          icon: 'pen-square',
          menuIndex: '2',
          menuList: [
            {
              path: '/manual/instrument',
              name: 'manual-instrument',
              icon: 'pen-square',
            },
            {
              path: '/manual/edit',
              name: 'manual-edit',
              icon: 'pen-square',
            },
            {
              path: '/manual/data',
              name: 'manual-data',
              icon: 'pen-square',
            },
            {
              path: (role) ? '/manual/import-kmrt' : '/manual/import',
              name: 'manual-import',
              icon: 'file-excel',
            },
          ],
        },
        {
          path: '/map',
          name: 'map',
          icon: 'map-marked-alt',
        },
        {
          path: '/layout',
          name: 'layout',
          icon: 'info-circle',
        },
        {
          path: '/export',
          name: 'export',
          icon: 'file-excel',
        },
        {
          path: '/monitor',
          name: 'monitor',
          icon: 'camera',
        },
        {
          path: '/rank',
          name: 'rank',
          icon: 'chart-bar',
        },
        {
          path: '/trajectory',
          name: 'trajectory',
          icon: 'signature',
        },
        {
          path: '/occ',
          name: 'occ',
          icon: 'tachometer-alt',
        },
        {
          path: '/noise',
          name: 'noise',
          icon: 'info-circle',
        },
        {
          path: '/details',
          name: 'details',
          icon: 'info-circle',
        },
        {
          path: '/contact',
          name: 'contact',
          icon: 'envelope',
        },
        {
          path: '/alerts',
          name: 'alerts',
          icon: 'bell',
        },
        {
          path: '/OCC-Contact',
          name: 'OCC-Contact',
          icon: 'envelope',
        },
        {
          path: '/advanced',
          name: 'AdvancedOptions',
          icon: 'user-cog',
        },
      ],

      adminMenuList: [
        {
          label: '設定',
          routes: [
            {
              path: '/admin/instrument',
              name: '儀器',
              icon: 'table',
            },
            {
              path: '/admin/instrument-group',
              name: '儀器組',
              icon: 'table',
            },
            {
              path: '/admin/instrumentType',
              name: '儀器類型',
              icon: 'table',
            },
            //{
            //  path: '/admin/instrumentComputer',
            //  name: '儀器公式',
            //  icon: 'table',
            //},
            {
              path: '/admin/project',
              name: '專案',
              icon: 'tasks',
            },
            {
              path: '/admin/area',
              name: '區域',
              icon: 'tasks',
            },
            {
              path: '/admin/station',
              name: '測站',
              icon: 'tasks',
            },
            {
              path: '/admin/user',
              name: '客戶',
              icon: 'tasks',
            },
            {
              path: '/admin/layout',
              name: '燈號',
              icon: 'table',
            },
            {
              path: '/admin/report',
              name: '報告設定',
              icon: 'table',
            },
            {
              path: '/admin/remark',
              name: '備註',
              icon: 'table',
            },
            {
              path: '/admin/document',
              name: '檔案',
              icon: 'table',
            },
            {
              path: '/admin/sms',
              name: '簡訊',
              icon: 'sms',
            },
            {
              path: '/admin/log',
              name: '登入記錄',
              icon: 'user',
            },
            {
              path: '/admin/testing',
              name: 'COM測試',
              icon: 'user',
            },
          ],
        },
        {
          label: '數據修改',
          routes: [
            {
              path: '/admin/edit/output',
              name: 'Output',
              icon: 'file-excel',
            },
            // {
            //   path: '/admin/edit/data',
            //   name: 'Data',
            //   icon: 'cogs'
            // },
            {
              path: '/admin/edit/instrument',
              name: 'Instrument',
              icon: 'file-excel',
            },
          ],
        },
      ],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin'];
    },
    userRole() {
      return this.$store.getters['user/details'].roles;
    },
    getMenuList() {
      const allowedTab = this.allowedTab;
      if([null,undefined].includes(allowedTab) || allowedTab.length == 0){
        console.log('get no allowedTab');
        return this.menuList;
      }
      return this.menuList.map(ele=>{
        if(ele.type === 'sub') return {
          ...ele,
          menuList: ele.menuList.filter(child=>{
            return allowedTab.includes(child.name);
          }),
        };
        else return ele;
      }).filter(ele=>{
        return allowedTab.includes(ele.name);
      });
    },
    allowedTab() {
      return this.$store.getters['project/selectedProject'].allowed_sidebar;
    },
  },
  mounted() {
    if(this.userRole == 'occ'){
      this.menuList = [
        {
          path: '/occ',
          name: 'occ',
          icon: 'tachometer-alt',
        },
        {
          path: '/chart',
          name: 'chart',
          icon: 'chart-line',
        },
        {
          path: '/alerts',
          name: 'alerts',
          icon: 'bell',
        },
        {
          path: '/OCC-Contact',
          name: 'OCC-Contact',
          icon: 'envelope',
        },
      ];
      //console.log(this.menuList);
    }
    else{
      if(this.userRole !== 'admin' && this.userRole !== 'thsr') {
        this.removeStormFromMenuList();
      }
      if(this.userRole !== 'admin' && this.userRole !== 'nbiot') {
        this.removeAdvancedOptionFromMenuList();
      }
    }
    Object.keys(sidebar).forEach(ele => {
      if (!sidebar[ele]) {
        this.removeFromSidebar(ele);
      }
    });
    console.log(this.menuList);
  },
  methods: {
    removeFromSidebar(key) {
      const itemIndex = this.menuList.findIndex(ele => ele.name === key);
      if (itemIndex !== -1 && this.userRole !== 'occ'){
        this.menuList.splice(itemIndex, 1);
      }
    },
    removeStormFromMenuList() {
      const stormIndex = this.menuList.findIndex(item => item.name === 'storm');
      if (stormIndex !== -1) {
        this.menuList.splice(stormIndex, 1);
      }
    },
    removeAdvancedOptionFromMenuList() {
      const aOIndex = this.menuList.findIndex(item => item.name === 'AdvancedOptions');
      if (aOIndex !== -1) {
        this.menuList.splice(aOIndex, 1);
      }
    },
    collapse(){
      this.$emit('collapse',this.isCollapse);
    },
  },
};
</script>

<style lang="stylus" scoped>
.sidebar-container
  height 100%
  min-height calc(100vh - 60px)
  background white
  overflow-x hidden
  .sidebar-scrollbar
    /deep/.el-scrollbar__wrap
      overflow-x hidden
.sidebar-collapse-switch
  padding-left: 10px
  padding-top: 10px
  padding-bottom: 10px
  //width 100%
  //height 20px
  //margin-left 1rem
.route-name
  margin-left 1rem
.el-menu-item.is-active
  border-left 4px solid #409EFF
  background-color #ecf5ff
.group-name
  font-size 14px
</style>
