<template>
  <div>
    <el-card v-loading="loading">
      <el-select v-model="instrumentType" placeholder="請選擇儀器類型" @change="changeInstrumentType">
        <el-option
          v-for="item in instrumentTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="timestamp" placeholder="請選擇時間" @change="changeTimestamp">
        <el-option
          v-for="item in timestampList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="area" placeholder="請選擇區域" @change="changeArea">
        <el-option
          v-for="item in areaList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-card>
    <el-card v-if="instrumentTypeInfo">
      {{ instrumentTypeInfo }}
    </el-card>
    <el-card v-if="instrumentList.length > 0">
      <hot-table id="hot-container" ref="hot" root="hot" :settings="settings" />
    </el-card>
  </div>
</template>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import { HotTable } from '@handsontable/vue';

MomentTimeZone();
export default {
  components: {
    HotTable,
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    settings() {
      const vm = this;
      return {
        data: vm.instrumentList,
        fixedColumnsLeft: 1,
        colHeaders: vm.columnsHeader,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        columns: vm.columns,
        licenseKey: 'non-commercial-and-evaluation',
      };
    },
    getInstrumentType(){
      return this.instrumentTypeList.find(ele=>{
        return ele.name === this.instrumentType;
      });
    },
    columns() {
      if(this.isContinuityType){
        let col = [
          'instrumentName',
          'unit',
          'A+',
          'A-',
          'F1',
          'A+',
          'A-',
          'result',
          'lastOutput',
          'diff',
          'WL',
          'AL',
          'WSL',
          'remark',
        ];
        return this.col.filter( ele => col.includes(ele.data));
      }

      let constCol = this.constCol;
      let insTypeObjKey = Object.keys(this.getInstrumentType);
      if( insTypeObjKey.includes('A+') && insTypeObjKey.includes('A-') && this.getInstrumentType['A+'] && this.getInstrumentType['A-'] )
      {
        constCol = this.constCol.filter(ele=>{
          return ele!='reading' && ele!='R0';
        });
      }
      return  this.col.filter(ele => {
        let insTypeObjKey = Object.keys(this.getInstrumentType);
        return (insTypeObjKey.includes(ele.data) && this.getInstrumentType[ele.data]) || constCol.includes(ele.data);
      });
    },
    columnsHeader() {
      if(this.isContinuityType){
        let col = [
          'instrumentName',
          'unit',
          'A+',
          'A-',
          'F1',
          'A+',
          'A-',
          'result',
          'lastOutput',
          'diff',
          'WL',
          'AL',
          'WSL',
          'remark',
        ];
        col = this.col.map((ele, index)=>{ return {...ele,index};} ).filter( ele => col.includes(ele.data));
        return this.colHeader.filter((ele, index)=>{
          return col.findIndex(c=> c.index == index) >= 0;
        });
      }

      let constCol = this.constCol;
      let insTypeObjKey = Object.keys(this.getInstrumentType);
      if( insTypeObjKey.includes('A+') && insTypeObjKey.includes('A-') && this.getInstrumentType['A+'] && this.getInstrumentType['A-'] )
      {
        constCol = this.constCol.filter(ele=>{
          return ele!='reading' && ele!='R0';
        });
      }
      let col = this.col.map((ele, index)=>{
        return {
          data: ele.data,
          index,
        };
      }).filter(ele => {
        let insTypeObjKey = Object.keys(this.getInstrumentType);
        return (insTypeObjKey.includes(ele.data) && this.getInstrumentType[ele.data]) || constCol.includes(ele.data);
      });
      return this.colHeader.filter((ele, index)=>{
        return col.findIndex(c=> c.index == index) >= 0;
      });
    },
    isContinuityType() {
      return this.instrumentType.includes('SIS');
    },
  },
  data(){
    return {
      constCol: [
        'instrumentName',
        'unit',
        'R0',
        'F1',
        'reading',
        'result',
        'lastOutput',
        'diff',
        'WL',
        'AL',
        'WSL',
        'remark',
      ],
      col: [
        {
          data: 'instrumentName',
          readOnly: true,
        },
        {
          data: 'unit',
          readOnly: true,
        },
        {
          data: 'R0',
          readOnly: true,
        },
        {
          data: 'A+initial',
          readOnly: true,
        },
        {
          data: 'A-initial',
          readOnly: true,
        },
        {
          data: 'F1',
          readOnly: true,
        },
        {
          data: 'reading',
          readOnly: true,
        },
        {
          data: 'A+',
          readOnly: true,
        },
        {
          data: 'A-',
          readOnly: true,
        },
        {
          data: 'result',
          readOnly: true,
        },
        {
          data: 'lastOutput',
          readOnly: true,
        },
        {
          data: 'diff',
          readOnly: true,
        },
        {
          data: 'WL',
          readOnly: true,
        },
        {
          data: 'AL',
          readOnly: true,
        },
        {
          data: 'WSL',
          readOnly: true,
        },
        {
          data: 'remark',
          readOnly: true,
        },
      ],
      colHeader: [
        '儀器名稱',
        '單位',
        '初值',
        '正向初值',
        '負向初值',
        '係數',
        '讀值',
        '正向讀值',
        '負向讀值',
        '變化量',
        '前次變化量',
        '差值',
        '注意值',
        '警戒值',
        '行動值',
        '備註',
      ],

      loading: false,
        
      timestampList: [],
      areaList: [],
      instrumentList: [],

      timestamp: '',
      area: '',

      instrumentTypeList: [],
      instrumentType: '',

      instrumentTypeInfo: '',
    };
  },
  async mounted(){
    this.loading = true;
    this.getInstrumentTypeList();
    await this.getInstruments();
    await this.getOutput();
    this.getTimestampList();
    this.loading = false;
  },
  methods: {
    async getInstrumentTypeList(){
      const res = await this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'instrument_type',
      });
      this.instrumentTypeList = res.data.filter(ele => ele.manual).map(ele=>{
        return {
          ...ele,
          value: ele.name,
          label: ele.name,
        };
      }).sort((a,b) => a.value[0].localeCompare(b.value[0]));
    },
    async getInstruments(){
      const res = await this.$axios({
        category: 'manual',
        method: 'get',
        apiName: `getManualInstrument/${this.selectedProject._id}`,
      });
      this.originInstrumentList = res.data;
      //console.log(this.originInstrumentList);
    },
    async getOutput(){
      const res = await this.$axios({
        category: 'manual',
        method: 'get',
        apiName: `getAllManualInstrumentOutput/${this.selectedProject._id}`,
      });

      let outputList = res.data;

      this.originInstrumentList = this.originInstrumentList.map(ins => {
        let instrumentOutputs = outputList.filter(output => output.name === ins.data[0].name);
        instrumentOutputs = instrumentOutputs/*.filter(output => output.name !== ins.data[0].name)*/.map(output => {
          return {
            ...output,
            diff: (![undefined, NaN, null].includes(output.lastOutput) && ![undefined, NaN, null].includes(output.reading2)) ? Number(output.reading2 - output.lastOutput).toFixed(4) : '',
          };
        });
        return {
          ...ins,
          outputList: instrumentOutputs,
        };
      });
      //console.log(this.originInstrumentList);
    },

    changeInstrumentType(){
      this.timestamp = '';
      this.area = '';
      this.getTimestampList();
      this.getAreaList();
      this.getInstrumentsFromTable();
      let insType = this.instrumentTypeList.find(insType => insType.name === this.instrumentType);
      this.instrumentTypeInfo = (insType) ? insType.info : '';
    },
    getTimestampList(){
      if(!this.instrumentType) return;
      let timsstampList = [];
      let instrumentList = this.originInstrumentList.filter(ele=>{
        return (!this.instrumentType || ele.instrument_type.name === this.instrumentType) /*&& (!this.area || ele.area === this.area)*/;
      });

      instrumentList.forEach(ins=>{
        ins.outputList.forEach(output=>{
          if(!timsstampList.includes(output.TIMESTAMP)) timsstampList.push(output.TIMESTAMP);
        });
      });

      this.timestampList = timsstampList.sort(
        (a,b) =>
        {
          return new Date(a).getTime() - new Date(b).getTime();
        },
      ).map(ele=>{ 
        return {
          value: ele,
          label: ele,
        };
      });

      if(this.timestampList) {
        let timestampIndex = this.timestampList.findIndex(time => {
          return time.value === this.timestamp;
        });
        if(timestampIndex < 0) this.timestamp = '';
      }
    },

    changeTimestamp(){
      this.getAreaList();
      this.getInstrumentsFromTable();
    },
    getAreaList(){
      if(!this.instrumentType) return;
      
      let areaList = this.originInstrumentList.filter(ele=>{
        //let isTime = ele.outputList.findIndex(output=>{
        //  return output.TIMESTAMP === this.timestamp;
        //}) >= 0;
        return (!this.instrumentType || ele.instrument_type.name === this.instrumentType) /*&& (!this.timestamp || isTime) */;
      }).map(ele=>{
        return ele.area;
      }).reduce((acc,cur)=>{
        if(acc.find(ele=>ele==cur)) return acc;
        else {
          acc.push(cur);
          return acc;
        }
      },[]);
      this.areaList = areaList.map(ele=>{ 
        return {
          value: ele,
          label: ele,
        };
      });
      
      if(this.areaList) {
        let areaIndex = this.areaList.findIndex(area => {
          return area.value === this.area;
        });
        if(areaIndex < 0) this.area = '';
      }
    },

    changeArea(){
      this.getTimestampList();
      this.getInstrumentsFromTable();
    },
    getInstrumentsFromTable(){
      if(!this.instrumentType && !this.timestamp && !this.area) return;
      this.instrumentList = this.originInstrumentList.filter(ins=>{
        let isArea = ins.area === this.area;
        let isTime = ins.outputList.findIndex(output=>{
          return output.TIMESTAMP === this.timestamp;
        }) >= 0;
        let isType = !this.instrumentType || this.instrumentType === ins.instrument_type.name;
        return isArea && isTime && isType;
      }).map(ins=>{
        let outputIndex = ins.outputList.findIndex(output=>{
          return output.TIMESTAMP === this.timestamp;
        });
        
        let instrumentDataIndex = this.getDataByTime(ins, this.timestamp);

        let WL_plus = ![null,NaN,undefined].includes(ins.data[instrumentDataIndex].WL_plus);
        let WL_minus = ![null,NaN,undefined].includes(ins.data[instrumentDataIndex].WL_minus);
        let AL_plus = ![null,NaN,undefined].includes(ins.data[instrumentDataIndex].AL_plus);
        let AL_minus = ![null,NaN,undefined].includes(ins.data[instrumentDataIndex].AL_minus);
        let WSL_plus = ![null,NaN,undefined].includes(ins.data[instrumentDataIndex].WSL_plus);
        let WSL_minus = ![null,NaN,undefined].includes(ins.data[instrumentDataIndex].WSL_minus);

        let WL = '';
        if(WL_plus && WL_minus) WL = `${ins.data[instrumentDataIndex].WL_plus}/${ins.data[instrumentDataIndex].WL_minus}`;
        else if(WL_plus) WL = `${ins.data[instrumentDataIndex].WL_plus}`;
        else if(WL_minus) WL = `${ins.data[instrumentDataIndex].WL_minus}`;
        let AL = '';
        if(AL_plus && AL_minus) AL = `${ins.data[instrumentDataIndex].AL_plus}/${ins.data[instrumentDataIndex].AL_minus}`;
        else if(AL_plus) AL = `${ins.data[instrumentDataIndex].AL_plus}`;
        else if(AL_minus) AL = `${ins.data[instrumentDataIndex].AL_minus}`;
        let WSL = '';
        if(WSL_plus && WSL_minus) WSL = `${ins.data[instrumentDataIndex].WSL_plus}/${ins.data[instrumentDataIndex].WSL_minus}`;
        else if(WSL_plus) WSL = `${ins.data[instrumentDataIndex].WSL_plus}`;
        else if(WSL_minus) WSL = `${ins.data[instrumentDataIndex].WSL_minus}`;

        return {
          instrumentName: ins.data[0].name,
          result: ins.outputList[outputIndex].reading2,
          'A+initial': ins.data[instrumentDataIndex]['A+'],
          'A-initial': ins.data[instrumentDataIndex]['A-'],
          F1: ins.data[instrumentDataIndex].F1,
          R0: ins.data[instrumentDataIndex].R0,
          offset: ins.data[instrumentDataIndex].offset,
          'A+': ins.outputList[outputIndex]['A+'],
          'A-': ins.outputList[outputIndex]['A-'],
          reading: ins.outputList[outputIndex].reading,
          lastOutput: ins.outputList[outputIndex].lastOutput,
          diff: ins.outputList[outputIndex].diff,
          unit: ins.data[instrumentDataIndex].unit,
          remark: ins.outputList[outputIndex].remark,
          WL,
          AL,
          WSL,
        };
      });
      //console.log(this.instrumentList);
      this.updateTable();
    },
    getDataByTime(instrument, time){
      let index = -1;
      let datas = instrument.data.sort((a, b)=>{
        return a.startDate - b.startDate;
      });
      for(let data of datas){
        index++;
        if(moment(data.startDate).isAfter(time)) {
          index--;
          break;
        }
      }
      return index;
    },
    updateTable() {
      if(this.instrumentList.length > 0)
        setTimeout(() => {
          this.$refs.hot.hotInstance.loadData(this.instrumentList);
        }, 100);
    },
  },
};
</script>
  
<style>
</style>