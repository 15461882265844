<template>
  <div v-loading="typeLoading || instrumentLoading || groupLoading">
    <!-- 上傳 .xlsx 檔案按鈕 -->
    <div class="button-container">
      <el-upload
        :show-file-list="false"
        accept=".xlsx"
        :before-upload="handleFileUpload"
      >
        <el-button type="primary">上傳 XLSX 檔案</el-button>
      </el-upload>
      <el-button @click="clearRow">清空所有</el-button>
      <el-button @click="dialogVisible = true">上傳</el-button>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <span>確定上傳?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="uploadRow()">上傳</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 列表顯示區 -->
    <el-table 
      :data="rows" 
      style="width: 100%; margin-top: 20px; " 
      border
    >
      <el-table-column label="分頁選單" width="180">
        <template #default="scope">
          <el-select v-model="scope.row.sheetName" @change="getInstrumentData(scope.row.sheetName)" placeholder="選擇分頁">
            <el-option
              v-for="sheet in sheetNames"
              :key="sheet"
              :label="sheet"
              :value="sheet"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="時間" width="140">
        <template #default="scope">
          {{ scope.row.timestamp }}
        </template>
      </el-table-column>
      <el-table-column label="內容">
        <template #default="scope">
          <div v-if="scope.row.group && scope.row.datas.length>0">
            <el-table :data="scope.row.datas" border style="width: 100%" size="mini">
              <el-table-column
                prop="depth"
                label="深度(m)"
              >
              </el-table-column>
              <el-table-column
                v-for="(value, key) in SIS_header"
                :key="key"
                :label="value.name"
                :prop="value.name"
              >
              </el-table-column>
            </el-table>
          </div>
          <div v-else-if="scope.row.datas.length>0">
            <el-table :data="scope.row.datas" border style="width: 100%" size="mini">
              <el-table-column
                v-for="(value, key) in Object.keys(scope.row.datas[0])"
                :key="key"
                :label="value"
              >
                <template #default="scope_cell">
                  <div :class="warning(scope.row, scope_cell.$index, value)">
                    {{ scope_cell.row[value] }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="儀器" width="200">
        <template #default="scope">
          <div v-for="(value, key) in scope.row.instruments" :key="key">
            {{ value ? value.data[0].name : "-" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="人工量測數據" width="200">
        <template #default="scope">
          <table>
            <tbody>
              <tr v-for="(value, key) in scope.row.reading" :key="key">
                {{ value ? (`${value['A+'] ? `${value['A+']} / ${value['A-']}` : value.reading1 ? value.reading1 : '-'} / ${value.reading3 ? value.reading3 : '-'}`) : "-" }}
              </tr>
            </tbody>
          </table>
        </template>
      </el-table-column>
      <!--el-table-column label="操作" width="100">
        <template #default="scope">
          <el-button @click="uploadRow(scope.row)">上傳</el-button>
        </template>
      </el-table-column-->
    </el-table>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
const ExcelJS = require('exceljs');

export default {
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  data() {
    return {
      dialogVisible: false,
      sheetNames: [], // 存放 xlsx 文件中的所有分頁名稱
      rows: [], // 列表，預設有一行
      originInstrumentList: [],
      stationsList: [],
      instrumentTypeList: [],
      workbook: null,
      instrumentLoading: false,
      typeLoading: false,
      groupLoading: false,
      sheet_printArea: {},
      instrumentGroupList: [],
      SIS_header: [
        {col:2, name: '初始值A+'},
        {col:3, name: '初始值A-'},
        {col:4, name: '觀測值A+'},
        {col:5, name: '觀測值A-'},
        {col:6, name: '變化量'  },
        {col:7, name: '位移量'  },
        {col:8, name: '備註'    },
      ],
      ELP_header: [
        {col:1, name: '裝設深度'},
        {col:2, name: '變換係數'},
        {col:3, name: '初始值'  },
        {col:4, name: '觀測值', reading1: true},
        {col:5, name: '水壓力', reading3: true},
      ],
    };
  },
  mounted(){
    this.getGroup();
    this.getInstrumentTypeList();
    this.getInstruments();
  },
  methods: {
    // 用於創建一個空白行
    createEmptyRow() {
      return {
        text: '', // 預設空白文字
        sheetName: '', // 預設沒有選擇分頁
        datas: [],
        timestamp: '',
        station: null,
        instruments: [],
        reading: [],
      };
    },
    // 處理文件上傳
    handleFileUpload(file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.$notifySuccess('載入成功');
        const workbook_2 = new ExcelJS.Workbook();
        await workbook_2.xlsx.load(e.target.result);
        workbook_2.eachSheet((worksheet, sheetId) => {
          const printArea = worksheet.pageSetup.printArea;
        
          //console.log(`Sheet: ${worksheet.name}`);
          if (printArea) {
            //console.log(`列印範圍: ${printArea}`);
            this.sheet_printArea[worksheet.name] = printArea;
          } else {
            //console.log('沒有設置列印範圍');
          }
        });

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array', cellStyles: true });
        const sheetsInfo = workbook.Workbook.Sheets;
        this.sheetNames = workbook.SheetNames.filter(sheetName => {
          //const sheet = workbook.Sheets[sheetName];
          const sheet = sheetsInfo.find(info => info.name === sheetName);
          return sheet && sheet.Hidden == 0; // 檢查是否為隱藏分頁
        });
        this.workbook = workbook;
        this.autoLoadAllTable();
      };
      reader.readAsArrayBuffer(file);
      return false; // 阻止默認的上傳行為
    },
    // 新增一行至列表
    addRow() {
      this.rows.push(this.createEmptyRow());
    },
    clearRow() {
      this.rows = [];
    },
    autoLoadAllTable(){
      let rowCount = this.rows.length - 1;
      let rowCounter = this.rows.length;
      for(let sheet of this.sheetNames){
        if(rowCount<rowCounter){
          this.addRow();
          rowCount++;
        }
        this.rows[rowCount].sheetName = sheet;
        this.getInstrumentData(sheet);
        let row = this.rows[rowCount];
        if(row.instrument_type){
          rowCounter++;
        }
        else{
          this.rows[rowCount] = this.createEmptyRow();
        }
      }
    },
    // 上傳行內容
    uploadRow() {
      this.dialogVisible = false;
      // 在這裡可以進行後端上傳或其他操作
      let data = this.rows.map(ele=>{
        return {
          timestamp: ele.timestamp,
          instrument_data: ele.reading.filter(reading=>reading),
        };
      }).filter(ele=>ele.timestamp);

      console.log(data);

      this.$axios({
        category: 'manual',
        method: 'put',
        apiName: 'importManualOutput',
        data,
      }).then(this.$notifySuccess('上傳成功'));
    },
    // 讀取所有儀器類型
    async getInstrumentTypeList(){
      this.typeLoading = true;
      const res = await this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'instrument_type',
      });
      this.instrumentTypeList = res.data.filter(ele => ele.manual).map(ele=>{
        return {
          ...ele,
          value: ele.name,
          label: ele.name,
        };
      });
      this.typeLoading = false;

      console.log(this.instrumentTypeList);
    },
    // 讀取所有人工量測儀器
    async getInstruments(){
      this.instrumentLoading = true;
      const res = await this.$axios({
        category: 'manual',
        method: 'get',
        apiName: `getManualInstrument/${this.selectedProject._id}`,
      });
      this.originInstrumentList = res.data;
      this.stationsList = this.originInstrumentList.reduce((pre, cur)=>{
        let stationIndex = pre.findIndex(ele=>ele.station==cur.station);
        if(stationIndex >= 0){
          pre[stationIndex].instruments.push(cur);
        }
        else{
          pre.push({
            station: cur.station,
            instruments: [cur],
          });
        }
        return pre;
      },[]);
      //this.getInstrumentsData();
      console.log(this.stationsList);
      this.instrumentLoading = false;

      console.log(this.originInstrumentList);
    },
    async getGroup(){
      this.groupLoading = true;
      let res = await this.$store
        .dispatch('data/getProjectInstrumentGroupList', {
          projectId: this.selectedProject._id,
        });
      this.instrumentGroupList = res;
      this.groupLoading = false;
    },
    getInstrumentData(sheetName){
      try{
        let rowindex = this.rows.findIndex(ele=>ele.sheetName === sheetName);
        const sheet = this.workbook.Sheets[sheetName];

        // 將分頁內容轉換為 JSON 格式
        let sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        let hiddenColumns = [];
        let hiddenRows = [];

        //console.log(sheetData);
        // 檢查合併的格
        if (sheet['!merges']) {
          console.log(this.$lodash.cloneDeep(sheet['!merges']));
          for(let merges of sheet['!merges']){
            let merges_data = sheetData[merges.s.r][merges.s.c];
            //console.log(merges.s.r, merges.s.c, merges.e.r, merges.e.c);
            //console.log(merges_data);
            if(merges_data){
              for(let row = merges.s.r; row <= merges.e.r; row++){
                //console.log(sheetData[row]);
                for(let col = merges.s.c; col <= merges.e.c; col++)
                {
                  if(sheetData[row])sheetData[row][col] = merges_data;
                }
                //console.log(sheetData[row]);
              }
            }
          }
        }

        // 檢查隱藏的欄
        if (sheet['!cols']) {
          hiddenColumns = sheet['!cols']
            .map((col, index) => (col && (col.hidden || (col?.wch === 0)) ? index : -1))
            .filter(index => index !== -1); // 僅保留隱藏欄的索引
        }

        console.log(sheet['!cols']);

        // 檢查隱藏的列
        if (sheet['!rows']) {
          hiddenRows = sheet['!rows']
            .map((row, index) => (row && row.hidden ? index : -1))
            .filter(index => index !== -1); // 僅保留隱藏列的索引
        }

        console.log(`get sheet print area: ${this.sheet_printArea[sheetName]}`);
        console.log(hiddenColumns);
        let print_area = this.parsePrintArea(this.sheet_printArea[sheetName]);

        //console.log(print_area);
        //console.log(sheetData);

        sheetData = sheetData.filter((ele, index)=>{
          return !hiddenRows.includes(index) && index < print_area.bottom;
        });

        sheetData = sheetData.map(ele=>ele.reduce((pre,cur,curindex)=>{
          if(curindex < print_area.right) pre[curindex] = cur;
          return pre;
        },[]));

        //console.log(sheetData);

        let starter = 0;
        let headerIndex = sheetData.findIndex(ele=>{
          return ['編號','儀器編號'].includes(ele[starter]);
        });

        if(headerIndex<0){
          starter = 1;
          headerIndex = sheetData.findIndex(ele=>{
            return ['編號','儀器編號'].includes(ele[starter]);
          });
        }

        let depth_col = starter + 1;

        console.log(sheetData[headerIndex][depth_col], headerIndex, depth_col);
        //用於SIS(連續型儀器)
        if(sheetData[headerIndex][depth_col].includes('觀測深度')){
          this.getInstrumentGroupData(sheetName, sheetData, print_area, hiddenColumns);
        }
        //用於非連續型儀器
        else if(headerIndex >= 0){
          console.log(headerIndex);
          let sub_headerIndex = headerIndex + 1;
          let timestamp = this.trytomatchTimestamp(sheetData, starter);
          //let station = this.trytomatchStation(sheetName);
          //this.rows[rowindex].station = station;

          let datas = [];
          for(let index = sub_headerIndex + 1;index<sheetData.length;index++){
            if(!sheetData[index][0]) continue;
            console.log(sheetData[index]);
            datas.push({
              '儀器編號': sheetData[index][0],
            });
          }

          timestamp = this.convertExcelDate(timestamp);
          //console.log(datas);

          let instruments = this.trytomatchInstrument(datas);
          console.log(instruments);
          let instrument_type = instruments?.find(ele=>ele)?.instrument_type;
          let header = null;
          if(instrument_type.header_setting)  header = instrument_type.header_setting;
          if(instrument_type?.name === 'ELP (水壓計)') header = this.ELP_header;

          //console.log('station', this.rows[rowindex].station);
          console.log('get instrument type', instrument_type);
          //console.log(sheetData);
          console.log('timestamp', timestamp);


          datas = datas.map((ele, index)=>{
            let skip = 0;
            let instrument_data = {};
            instrument_data = header.reduce((pre, cur, curindex)=>{
              while(hiddenColumns.includes(cur.col + skip)){
                skip++;
              }
              //console.log(starter, cur.col, skip);
              pre[cur.name] = sheetData[sub_headerIndex + 1 + index][starter + cur.col + skip];
              return pre;
            },{});
            return {
              ...ele,
              ...instrument_data,
            };
          });

          instruments = this.trytomatchInstrument(datas);

          console.log(datas);

          this.rows[rowindex].timestamp = timestamp;
          this.rows[rowindex].datas = datas;
          this.rows[rowindex].instruments = instruments;
          this.rows[rowindex].instrument_type = instrument_type;

          let waring_check = this.check(datas, instrument_type);
          console.log(waring_check);
          this.rows[rowindex].warning_cell = waring_check;

          this.rows[rowindex].reading = this.rows[rowindex].instruments.map(ins=>{
            if(!ins) return null;
            let reading1_header = header.find(ele => ele.reading1)?.name;
            let reading3_header = header.find(ele => ele.reading3)?.name;
            let Aplus_header = header.find(ele => ele['A+'])?.name;
            let Aminus_header = header.find(ele => ele['A-'])?.name;
            //console.log(header);
            //console.log(header.find(ele => ele.reading1));
            //console.log(header.find(ele => ele.reading3));
            //console.log(reading1_header);
            //console.log(reading3_header);
            //console.log(ins.xlsx_data);
            let reading1 = ins.xlsx_data[reading1_header];
            let reading3 = ins.xlsx_data[reading3_header];
            let Aplus = ins.xlsx_data[Aplus_header];
            let Aminus = ins.xlsx_data[Aminus_header];
            reading1 = isNaN(Number(reading1)) ? NaN : reading1;
            reading3 = isNaN(Number(reading3)) ? NaN : reading3;
            return {
              instrument: ins,
              reading1,
              'A+': Aplus,
              'A-': Aminus,
              reading3,
            };
          });  
        }
        else{
          console.log('??');
        }

        console.log(this.rows);
        this.$forceUpdate();
      }
      catch(e){
        console.log(e);
        //console.log('Error occurred at line:', this.getErrorLine(e));
        if (e.message.includes('Invalid time value')){
          this.$notifyError('時間格式錯誤，請重新確認');
        }
        else {
          this.$notifyError('匯入錯誤，請重新確認格式');
        }
        this.rows[this.rows.length] = this.createEmptyRow();
      }
    },
    convertExcelDate(excelDate) {
      // Excel 日期的起始時間是 1900-01-01
      const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel 日期從 1900-01-01 開始，但包含閏年錯誤
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const date = new Date(excelEpoch.getTime() + excelDate * millisecondsPerDay);

      // 格式化為 YYYY-MM-DD HH:mm
      return date.toISOString().slice(0, 16).replace('T', ' ');
    },
    trytomatchTimestamp(sheetData, starter) {
      return (sheetData.find(ele=>{
        if(!ele[starter]) return false;
        if(ele[starter] && ((ele[starter].toString()).includes('本次觀測日期') || (ele[starter].toString()).includes('觀測日期'))) return true;
        if(['TIMESTAMP','timestamp'].includes(ele[starter])) return true;
        return false;
      }))?.[starter+1];
    },
    trytomatchStation(sheetName) {
      const result = sheetName.replace(/\s+/g, '');
      for(let stationData of this.stationsList){
        //console.log(`${sheetName}-${stationData.station}`);
        if(result == stationData.station) return stationData;
      }
      console.log(`get no station name: ${result}`);
      return false;
    },
    trytomatchGroup(sheetName) {
      const result = sheetName.replace(/\s+/g, '');
      for(let group of this.instrumentGroupList){
        //console.log(group);
        if(result == group.name) return group;
      }
      console.log(`get no group name: ${result}`);
      return false;
    },
    trytomatchInstrument(datas){
      let instruments = [];
      for(let data of datas){
        let ins_index = this.originInstrumentList.findIndex(ele=>ele.data[0].name === data['儀器編號']);
        if(ins_index >= 0){
          instruments.push({
            ...this.originInstrumentList[ins_index],
            xlsx_data: data,
          });
        }
        else{
          instruments.push(null);
        }
      }
      return instruments;
    },
    parsePrintArea(printArea) {
      if (!printArea || typeof printArea !== 'string') {
        throw new Error('Invalid print area');
      }
    
      // 拆解範圍，例如 "A1:B2"
      const [startCell, endCell] = printArea.split(':');
    
      // 解析單一儲存格的列和行
      const parseCell = (cell) => {
        const match = cell.match(/^([A-Z]+)(\d+)$/); // 解析格式，例如 "A1"
        if (!match) {
          throw new Error(`Invalid cell format: ${cell}`);
        }
      
        const column = match[1];
        const row = parseInt(match[2], 10);
      
        // 將列從字母轉為數字 (例如 A = 1, B = 2, ..., Z = 26, AA = 27)
        const columnNumber = column.split('').reduce((acc, char) => {
          return acc * 26 + (char.charCodeAt(0) - 64); // A 的 ASCII 為 65
        }, 0);
      
        return { column: columnNumber, row };
      };
    
      // 解析起始與結束儲存格
      const start = parseCell(startCell);
      const end = parseCell(endCell);
    
      return {
        left: start.column,
        right: end.column,
        top: start.row,
        bottom: end.row,
      };
    },
    getInstrumentGroupData(sheetName, sheetData, print_area, hiddenColumns){
      let rowindex = this.rows.findIndex(ele=>ele.sheetName === sheetName);
      let starter = print_area.left - 1;
      let headerIndex = sheetData.findIndex(ele=>{
        return ['儀器編號'].includes(ele[starter]);
      });
      console.log(`try to match instrument group ${sheetName}`);

      let sub_headerIndex = headerIndex + 1;
      //let sub_headerIndex2 = headerIndex + 2;

      let depth_array = sheetData.reduce((pre, cur, curindex)=>{
        if(curindex < sub_headerIndex + 1) return pre;
        if(!cur[starter + 1]) return pre;
        pre.push(cur[starter + 1]);
        return pre;
      },[]).sort((a, b) => Math.abs(b) - Math.abs(a));
      let depth_max = depth_array[0];

      if(headerIndex>=0) console.log('sheet-', sheetName, 'found header in', headerIndex, 'found "深度"', depth_max);
      else return;

      let depth_col = starter + 1;

      if(!sheetData[headerIndex][depth_col].includes('觀測深度')) {
        console.log('sheet-', sheetName, 'cannot find depth column', sheetData[headerIndex][depth_col]);
        return;
      }

      let datas = [];
      for(let i = sub_headerIndex + 1; i < sheetData.length; i++){
        let depth = Number(sheetData[i][depth_col]);
        if(isNaN(depth)) break;
        //let data = sheetData[i].slice(starter + 1, starter + 1 + 6);
        let skip = 0;
        datas.push({
          depth,
          ...(this.SIS_header.reduce((pre, cur, curindex)=>{
            while(hiddenColumns.includes(cur.col + skip)){
              skip++;
            }
            //console.log(starter, cur.col, skip);
            pre[cur.name] = sheetData[i][starter + cur.col + skip];
            return pre;
          },{})),
        });
        console.log('get depth',depth,'data:', datas[datas.length-1]);
      }

      let timestamp = this.trytomatchTimestamp(sheetData, starter);

      if(!timestamp) timestamp = this.trytomatchTimestamp(sheetData, starter+1);

      this.rows[rowindex].timestamp = this.convertExcelDate(timestamp);
      this.rows[rowindex].datas = datas;

      this.rows[rowindex].group = this.trytomatchGroup(sheetName);
      //this.rows[rowindex].station = this.trytomatchStation(sheetName);
      
      this.rows[rowindex].instruments = this.rows[rowindex].group && this.rows[rowindex].group.instrumentList.map(ele=>{
        let instrument = this.originInstrumentList.find(ins=>ins._id.toString() === ele.instrument.toString());
        if(!instrument) return undefined;
        let depth_data = datas.find(row=>row.depth===ele.depth);
        return {
          ...instrument,
          depth_data,
        };
      });
      //console.log(this.rows[rowindex]);
      let instrument_type = this.rows[rowindex].instruments?.find(ele=>ele)?.instrument_type;
      this.rows[rowindex].instrument_type = instrument_type;
      this.rows[rowindex].reading = this.rows[rowindex].group && this.rows[rowindex].instruments.map(ins=>{
        if(!ins) return null;
        let reading1_A_plus  = ins.depth_data['觀測值A+'];
        let reading1_A_minus = ins.depth_data['觀測值A-'];
        let reading3 = ins.depth_data['變化量'];
        reading1_A_plus  = isNaN(Number(reading1_A_plus )) ? NaN : reading1_A_plus ;
        reading1_A_minus = isNaN(Number(reading1_A_minus)) ? NaN : reading1_A_minus;
        reading3 = isNaN(Number(reading3)) ? NaN : reading3;
        return {
          instrument: ins,
          'A+': reading1_A_plus,
          'A-': reading1_A_minus,
          reading3,
        };
      });
    },
    check(datas, instrument_type){
      return datas.map(data=>{
        if(!instrument_type || !instrument_type.header_setting){
          return Object.keys(data).reduce((pre, cur)=>{
            pre[cur] = false;
            return pre;
          },{});
        }
        return instrument_type.header_setting.reduce((pre, cur)=>{
          //pre[cur.name] = false;
          pre[cur.name] = false;

          if(![null, undefined].includes(cur.lt)){
            console.log(pre[cur.name], data[cur.name] <= cur.lt);
            pre[cur.name] = pre[cur.name] || (data[cur.name] < cur.lt);
          }
          if(![null, undefined].includes(cur.gt)){
            console.log(pre[cur.name], data[cur.name] <= cur.gt);
            pre[cur.name] = pre[cur.name] || (data[cur.name] > cur.gt);
          }
          if(![null, undefined].includes(cur.lte)){
            console.log(pre[cur.name], data[cur.name] <= cur.lte);
            pre[cur.name] = pre[cur.name] || (data[cur.name] <= cur.lte);
          }
          if(![null, undefined].includes(cur.gte)){
            console.log(pre[cur.name], data[cur.name] <= cur.gte);
            pre[cur.name] = pre[cur.name] || (data[cur.name] >= cur.gte);
          }
          
          return pre;
        },{});
      });
    },
    warning(data, row, col){
      // 針對第二列，分數小於 60 的單元格變紅色
      //console.log('test',data);
      //console.log('test2', col, row);
      if (data?.warning_cell[row][col]) {
        //console.log('warning-cell');
        return 'warning-cell';
      }
      return '';
    },
    // 提取出錯行號的函數
    getErrorLine(error) {
      if (error.stack) {
        // 分析堆疊訊息中的行號
        const stackLines = error.stack.split('\n');
        const relevantLine = stackLines[1]; // 堆疊訊息中的第二行包含出錯位置
        const match = relevantLine.match(/:(\d+):\d+\)?$/); // 匹配行號
        if (match) {
          return parseInt(match[1], 10); // 返回行號
        }
      }
      return 'Unknown line'; // 如果無法解析行號
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex; /* 啟用 Flexbox */
  align-items: center; /* 垂直置中 */
  justify-content: flex-start; /* 控制子元素之間的間距 */
  gap: 10px; /* 子元素之間的間距 */
  flex-direction: row;
}

.warning-cell {
  background: #ff7575;
}
</style>
