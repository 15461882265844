<template>
  <div>
    <div>
      取得所有的Data Index :
      <i v-if="isGetAllDocumentsWithDataIndexes" class="el-icon-check"></i>
      <i v-else class="el-icon-close"></i>

      取得所有的Instrument :
      <i v-if="isGetAllInstrumentsData" class="el-icon-check"></i>
      <i v-else class="el-icon-close"></i>
    </div>
    <div ref="functionShowLabel">
      算式: {{ computeFunction }}
    </div>
    <hr/>
    <div>手動編輯區域</div>
    <el-input label="手動編輯區域" v-model="newComputeFunction" @change="handleFunctionChange"/>
    <hr/>
    <div>
      <el-button @click="add('+')">+</el-button>
      <el-button @click="add('-')">-</el-button>
      <el-button @click="add('*')">X</el-button>
      <el-button @click="add('/')">/</el-button>
      <el-button @click="add('(')">(</el-button>
      <el-button @click="add(')')">)</el-button>
    </div>

    <div>
      <el-button @click="add('sin(')">sin</el-button>
      <el-button @click="add('cos(')">cos</el-button>
      <el-button @click="add('tan(')">tan</el-button>
      <el-button @click="add('deg2rad(')">角度轉弧度</el-button>
      <el-button @click="add('rad2deg(')">弧度轉角度</el-button>
    </div>

    <div>
      <el-select v-model="selectInstrument" filterable popper-class="ouo-1" placeholder="選擇儀器">
        <el-option
          v-for="item in instrumentOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="addInstrument()">添加</el-button>
    </div>

    <div>
      <el-cascader v-model="selectDataIndex" filterable popper-class="ouo-2" :options="dataIndexesOptions" placeholder="選擇header"/>
      <el-button @click="addDataIndex()">添加</el-button>
    </div>
    <hr/>
    <div>
      <el-button @click="encoderFunction">
        轉換
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    computeFunction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newComputeFunction: '',
      isGetAllInstrumentsData: false,
      isGetAllDocumentsWithDataIndexes: false,

      instrumentsList: [],
      documentDataIndexesList: [],

      tempList: [],

      selectInstrument: '',
      selectDataIndex: '',

      instrumentOptions: [],
      dataIndexesOptions: [],
    };
  },
  methods: {
    start(){
      console.log('start');
      this.decoderFunction();

      if(!this.isGetAllInstrumentsData) this.getAllInstrumentsData();
      if(!this.isGetAllDocumentsWithDataIndexes) this.getAllDocumentsWithDataIndexes();
    },
    handleFunctionChange() {
      //this.$refs.functionShowLabel.
      this.$forceUpdate();
    },
    add(str){
      this.newComputeFunction += str;
    },
    encoderFunction(){
      let computeFunction = this.newComputeFunction;

      this.tempList.forEach( ele => {
        let index = computeFunction.includes(ele.replacement);
        if(index >= 0){
          computeFunction = computeFunction.replace(ele.replacement, ele.origin);
        }
      });

      this.computeFunction = computeFunction;
    },
    decoderFunction(){
      let computeFunction = this.computeFunction;

      if(this.isGetAllInstrumentsData){
        console.log('isGetAllInstrumentsData');
        const instrumentObjectIdRegex = /(instrument):([a-f0-9]{24})/g;
        let match;
        while ((match = instrumentObjectIdRegex.exec(computeFunction)) !== null) {
          const table = match[1];
          const objectId = match[2];
          const matchInstrument = this.instrumentsList.find(ele => ele._id == objectId);
          if(matchInstrument){
            //let newstr = str.replace(`${table}:${objectId}`, value.reading);
            computeFunction = computeFunction.replace(`${table}:${objectId}`, matchInstrument.name);
          }

          this.tempList.push({
            replacement: matchInstrument.name,
            origin: `${table}:${objectId}`,
          });
        }
      }
      if(this.isGetAllDocumentsWithDataIndexes){
        console.log('isGetAllDocumentsWithDataIndexes');
        const dataIndexObjectIdRegex = /(data):([a-f0-9]{24})/g;
        let match;
        while ((match = dataIndexObjectIdRegex.exec(computeFunction)) !== null) {
          const table = match[1];
          const objectId = match[2];

          const matchDocument = this.documentDataIndexesList.find(doc => doc.dataIndexes.find(dataIndex => dataIndex._id == objectId));
          if(matchDocument){
            //let newstr = str.replace(`${table}:${objectId}`, value.reading);
            const matchDataIndex = matchDocument.dataIndexes.find(dataIndex => dataIndex._id == objectId);
            computeFunction = computeFunction.replace(`${table}:${objectId}`, `${matchDocument.name}:${matchDataIndex.name}`);

            this.tempList.push({
              replacement: `${matchDocument.name}:${matchDataIndex.name}`,
              origin: `${table}:${objectId}`,
            });
          }
        }
      }

      this.newComputeFunction = computeFunction;

      console.log(this.newComputeFunction);
    },

    getAllInstrumentsData(){
      this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'instrument/option',
      })
        .then(res => {
          this.instrumentsList = res.data;
          this.isGetAllInstrumentsData = true;
          this.decoderFunction();

          this.instrumentOptions = this.instrumentsList.map((ins, index)=>{
            return {
              value: ins._id,
              label: ins.name,
            };
          });

          this.$forceUpdate();
        });
    },
    getAllDocumentsWithDataIndexes(){
      this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'document/getAllIndex',
      })
        .then(res => {
          this.documentDataIndexesList = res.data;
          this.isGetAllDocumentsWithDataIndexes = true;
          this.decoderFunction();

          this.dataIndexesOptions = this.documentDataIndexesList.map((doc, index)=>{
            return {
              value: {
                _id: doc._id,
                name: doc.name,
              },
              label: doc.name,
              children: doc.dataIndexes.map(dataIndex=>{
                return {
                  value: {
                    _id: dataIndex._id,
                    name: dataIndex.name,
                  },
                  label: dataIndex.name,
                };
              }),
            };
          });

          this.$forceUpdate();
        });
    },

    addInstrument(){
      console.log(this.selectInstrument);
      const instrumentName = this.instrumentsList.find(ele => ele._id === this.selectInstrument).name;
      this.add(instrumentName);
      this.tempList.push({
        replacement: instrumentName,
        origin: `instrument:${this.selectInstrument}`,
      });

      this.selectInstrument = '';
    },
    addDataIndex(){
      console.log(this.selectDataIndex);
      this.add(`${this.selectDataIndex[0].name}:${this.selectDataIndex[1].name}`);
      this.tempList.push({
        replacement: `${this.selectDataIndex[0].name}:${this.selectDataIndex[1].name}`,
        origin: `data:${this.selectDataIndex[1]._id}`,
      });
      this.selectDataIndex = '';
    },
  },
};
</script>

<style lang="stylus" scoped>

/deep/ .el-popper{
  position absolute !important;
}
</style>

<style lang="stylus">
.ouo-1{
  position relative !important;
  width: 300px;
  top: -500px !important;
}
.ouo-2{
  position relative !important;
  width: 300px;
  top: -450px !important;
}
</style>