<template>
  <div id="hot-app">
    <HotTable ref="msgSettingsTable" root="msgSettingsTable" :settings="settings"></HotTable>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';

export default {
  props: {
    instrumentList: {
      type: Array,
      required: true,
    },
  },
  components: {
    HotTable,
  },
  computed: {
    settings() {
      const vm = this;
      if(vm.localInstrumentList.length != vm.instrumentList.length)this.instrumentListInit();
      return {
        fixedColumnsLeft: 1,
        data: vm.localInstrumentList,
        columns: [
          { data: 'name', type: 'text' },
          { data: 'down', type: 'checkbox' },
          { data: 'wsl', type: 'checkbox' },
          { data: 'al', type: 'checkbox' },
          { data: 'wl', type: 'checkbox' },
          { data: 'rate1hrwsl', type: 'checkbox' },
          { data: 'rate1hral', type: 'checkbox' },
          { data: 'rate1hrwl', type: 'checkbox' },
          { data: 'rate24hrwsl', type: 'checkbox' },
          { data: 'rate24hral', type: 'checkbox' },
          { data: 'rate24hrwl', type: 'checkbox' },
        ],
        colHeaders: [
          '儀器名稱', 
          '簡訊故障', 
          '簡訊行動值(WSL)', 
          '簡訊警戒值(AL)', 
          '簡訊警戒值(WL)',
          '1小時速率簡訊行動值(WSL)', 
          '1小時速率簡訊警戒值(AL)', 
          '1小時速率簡訊警戒值(WL)',
          '24小時速率簡訊行動值(WSL)', 
          '24小時速率簡訊警戒值(AL)', 
          '24小時速率簡訊警戒值(WL)',
        ],
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        undo: true,
        async afterChange(changes, source) {
          if (source !== 'loadData') {
            changes.forEach(([row, prop, oldVal, newVal]) => {
              const list = [
                'down', 
                'wsl', 
                'al', 
                'wl',
                'rate1hrwsl', 
                'rate1hral', 
                'rate1hrwl',
                'rate24hrwsl', 
                'rate24hral', 
                'rate24hrwl',
              ];
              if (list.includes(prop) && oldVal !== newVal) {
                let objectList = this.getSourceData();
                const dataList = this.getData();

                const obj = objectList.find(
                  instrument => {
                    return instrument.name === dataList[row][0];
                  },
                );
                
                console.log(obj.name);
                vm.handleMessageEnabledChange(obj);
              }
            });
          }
        },
        contextMenu: {
          items: {
            separator: Handsontable.plugins.ContextMenu.SEPARATOR,
            copy: {
              name: '复制',
            },
            undo: {
              name: '撤消',
            },
            separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
            remove_row: {
              name: '删除行',
            },
          },
        },
        filters: true,
        // dropdownMenu: true,
        dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
        licenseKey: 'non-commercial-and-evaluation',
      };
    },
  },
  data() {
    return {
      pendingChanges: [],
      saveTimeout: null,
      localInstrumentList: [],
    };
  },
  mounted(){
    this.instrumentListInit();
  },
  methods: {
    instrumentListInit(){
      this.localInstrumentList = this.instrumentList.map(instrument => ({
        _id: instrument._id,
        name: instrument.latestVersion.name,
        down: instrument.config.message.down.enabled,
        wsl: instrument.config.message.WSL.enabled,
        al: instrument.config.message.AL.enabled,
        wl: instrument.config.message.WL.enabled,
        'rate1hrwsl': instrument.config.message.rate1Hr.WSL.enabled,
        'rate1hral':  instrument.config.message.rate1Hr.AL.enabled,
        'rate1hrwl':  instrument.config.message.rate1Hr.WL.enabled,
        'rate24hrwsl': instrument.config.message.rate24Hr.WSL.enabled,
        'rate24hral':  instrument.config.message.rate24Hr.AL.enabled,
        'rate24hrwl':  instrument.config.message.rate24Hr.WL.enabled,
        config: instrument,
      }));
      this.updateTable();
    },
    async handleMessageEnabledChange(instrument) {
      let data = instrument.config;

      const parseBool = val => {
        if (typeof val === 'string') {
          return val.toLowerCase() === 'true';
        }
        return val;
      };

      data.config.message.down.enabled = parseBool(instrument.down);
      data.config.message.WSL.enabled = parseBool(instrument.wsl);
      data.config.message.AL.enabled = parseBool(instrument.al);
      data.config.message.WL.enabled = parseBool(instrument.wl);
      data.config.message.rate1Hr.WSL.enabled = parseBool(instrument.rate1hrwsl);
      data.config.message.rate1Hr.AL.enabled = parseBool(instrument.rate1hral);
      data.config.message.rate1Hr.WL.enabled = parseBool(instrument.rate1hrwl);
      data.config.message.rate24Hr.WSL.enabled = parseBool(instrument.rate24hrwsl);
      data.config.message.rate24Hr.AL.enabled = parseBool(instrument.rate24hral);
      data.config.message.rate24Hr.WL.enabled = parseBool(instrument.rate24hrwl);

      this.pendingChanges.push({
        category: 'admin',
        method: 'put',
        apiName: `instrument/${instrument._id}`,
        data,
      });

      // Clear the previous timeout
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }

      // Set a new timeout
      this.saveTimeout = setTimeout(this.saveChanges, 1000); // adjust this time to fit your needs
    },

    async saveChanges() {
      try {
        // Here you should replace this with the way you actually make batch updates in your API
        for (let change of this.pendingChanges) {
          await this.$axios(change);
        }
        this.$notifySuccess('更新成功');
      } catch (err) {
        this.$notifyError(err.response.data.message);
      } finally {
        this.pendingChanges = [];
      }
    },
    updateTable() {
      setTimeout(() => {
        this.$refs.msgSettingsTable.hotInstance.loadData(this.localInstrumentList);
      }, 100);
    },
  },
};
</script>
