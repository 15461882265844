import Vue from 'vue';
import { Notification, MessageBox } from 'element-ui';
import { i18n } from '@/i18n/index.js';

Vue.prototype.$confirmCreate = title => {
  return MessageBox.confirm(`${title}, 是否繼續?`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'info',
  }).catch(() => {
    Notification.info({
      title: i18n.messages[i18n.locale].Notify.cancelled,
      duration: 3500,
    });
  });
};
Vue.prototype.$confirmEdit = title => {
  return MessageBox.confirm(`${title}, 是否繼續?`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'info',
  }).catch(() => {
    Notification.info({
      title: i18n.messages[i18n.locale].Notify.cancelled,
      duration: 3500,
    });
  });
};

Vue.prototype.$confirmDelete = title => {
  return MessageBox.confirm(`${title}, 是否繼續?`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).catch(() => {
    Notification.error({
      title: '已取消删除',
      duration: 3500,
    });
  });
};

// const NotifyError = (error, title='錯誤') => {
//   Notification.error({
//     title,
//     message: error.response.data.message,
//     duration: 3500,
//   });
//   throw new Error(error);
// };

const NotifyError = error => {
  Notification.closeAll();
  let errorMessage = '';
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error.response) {
    errorMessage = error.response.data.message;
  }
  Notification.error({
    title: i18n.messages[i18n.locale].Notify.error,
    message: errorMessage,
    duration: 3500,
  });
};

Vue.prototype.$notifyError = NotifyError;

// Vue.prototype.$notifyError = (title = '錯誤發生', errorMessage) => {
//   Notification.error({
//     title,
//     message: errorMessage,
//     duration: 3500,
//   });
// };

Vue.prototype.$notifySuccess = (title = i18n.messages[i18n.locale].Notify.success, message) => {
  Notification({
    title,
    message,
    type: 'success',
    duration: 2000,
  });
};

Vue.prototype.$notifyInfo = (title, message) => {
  Notification.info({
    title,
    message,
    duration: 2500,
  });
};

export { NotifyError };
