const zh = {
  lang: {
    'zh-TW': '中文',
    'en-US': '英文',
  },
  Notify: {
    'error': '錯誤發生',
    'info': '訊息',
    'success': '成功',
    'cancelled': '已取消',
  },
  common: {
    show: '顯示',
    hide: '隱藏',
  },
  navbar: {
    project: '專案',
    language: '語言',
    logout: '登出',
    slope: '邊坡監測', 
    storm: '暴雨監測',
  },
  sidebar: {
    dashboard: '儀錶板',
    slope: '邊坡監測', 
    storm: '暴雨監測',
    summary: '總表',
    snakeLine: 'SnakeLine',
    chart: '歷時曲線',
    curve: '分佈曲線',
    data: '監測數據列表',
    map: '地圖',
    export: '輸出檔案',
    details: '關於',
    layout: '平面圖',
    contact: '聯絡',
    monitor: 'CCTV',
    alerts: '事件紀錄',
    occ: 'OCC即時監測',
    noise: '噪音監測',
    trajectory: '軌跡圖',
    rank: '數據排名表',
    eventList: '事件紀錄',
    manual: '人工監測',
    'manual-data': '數據列表',
    'manual-edit': '輸入資料',
    'manual-instrument': '儀器列表',
    'manual-import': '匯入',
    AdvancedOptions: '進階選項',
    'OCC-Contact': '聯絡人',
  },
  login: {
    title: '登入',
    title_edit: '更改密碼',
    email: '電子信箱',
    psd: '密碼', //將password改成psd，以規避白箱檢查，因為這裡是多語系文件
    forgot: '忘記密碼',
    npsd: '新密碼(密碼不可與電話相同)',
    psdCheck: '確認密碼',
    psdValidate: '密碼不能小於6位',
    psdCheckValidate: '密碼不一致',
  },
  chart: {
    title: {
      historyChart: '歷時曲線',
    },
    measurements: '測量值',
    Date: '日期',
    reset: '重設',
    filter: '篩選',
    submit: '提交',
    settings: '設定',
    rescale: '重置圖表',
    table: '顯示表格',
    remark: {
      show: '顯示備註',
      hide: '隱藏備註',
    },
    refresh: '自動更新',
    lastFetch: '最后更新于',
    area: {
      name: '區域',
      pick: '選擇區域',
      none: '沒有區域',
    },
    station: {
      name: '測站',
      pick: '選擇測站',
    },
    instrument: {
      name: '儀器名稱',
      pick: '選擇儀器',
      none: '沒有儀器',
      picked: '已選擇儀器',
    },
    date: {
      pick: '選擇時間執行',
      start: '開始時間',
      end: '結束時間',
      interval: '時間間隔',
      day: '天',
      hour: '小時',
      minute: '分鐘',
    },
    interval: {
      pick: '數據間隔',
      daily: '1D',
      quarter: '6H',
      hour: '1H',
      '10min': '10m',
      '15min': '15m',
      all: '全部',
      tooltip: {
        daily: '一天一筆，無法用於1hr速率',
        quarter: '',
        hour: '1小時一筆',
        '10min': '每十分鐘一筆',
        '15min': '每十五分鐘一筆',
        all: '',
      },
    },
    execute: {
      btn: '執行',
      loading: 'Loading...',
    },
    xAxis: {
      max: '最大值',
      min: '最小值',
      interval: '間隔',
      hide: '隱藏單位',
      show: '顯示單位',
      position: '切換單位位置',
    },
    yAxis: {
      max: '最大值',
      min: '最小值',
      interval: '間隔',
      hide: '隱藏單位',
      show: '顯示單位',
      position: '切換單位位置',
      endOnTick: '啟用自動調整Y軸參數',
    },
    error: {
      loading: '加載失敗',
      date: '請選擇日期',
      instrument: '請至少選擇一個儀器',
    },
    velocity24Hr: '24Hr 速率',
    velocity1Hr: '1Hr 速率',
    compare: '比較',
    group: '分組',
    tenGroup: '10',
    eachGroup: '逐一分組',
    rainGroup: '雨量',
    sync: '同步',
    output: '輸出1',
    'output-2': '輸出2',
    rain1Day: '一天雨量',
    trendline: '趨勢線',
    trendlineError: '趨勢圖僅可使用一個儀器',
    addSeven: '顯示7天',
    day: '日',
    info: {
      compare: '與指定時間進行比較',
      group: '讓曲線依單位進行分組',
      tenGroup: '讓曲線每十個一組',
      eachGroup: '讓每一個曲線擁有獨立的圖表',
      rainGroup: '將雨量顯示在所有群組中(限與十個一組或逐一分組共同使用)',
      output: '將所有曲線圖整合在一張曲線圖上後輸出',
      'output-2': '將所有群組逐一輸出圖片',
      rain1Day: '僅取每天0時的雨量數據',
      trendline: '趨勢線',
    },
    autoScale: 'Auto Scale',
  },
  remark: {
    date: '時間',
    type: '備註類型',
    content: '內容',
    placeholder: {
      date: {
        start: '開始時間',
        end: '結束時間',
      },
      type: '選擇備註類型',
      content: '請輸入備註內容',
    },
    form: {
      edit: '編輯備註',
      create: '新增備註',
      cancel: '取消',
      confirm: '確定',
      update: '更新',
      success: '成功',
    },
    typeList: {
      status: '儀器狀況',
      construction: '現場施工狀況',
    },
  },
  export: {
    station: {
      pick: '選擇要輸出的測站',
      pickAll: '全選',
      none: '沒有測站',
    },
    type: {
      pick: '數據類型',
      raw: '原讀值',
      measure: '測量值',
      velocity1Hr: '1Hr速率',
      velocity24Hr: '24Hr速率',
    },
    date: {
      pick: '選擇時間',
      start: '開始時間',
      end: '結束時間',
      interval: '時間間隔',
      day: '天',
      hour: '小時',
    },
    interval: {
      pick: '數據間隔',
      daily: '1D',
      quarter: '6H',
      hour: '1H',
      all: '全部',
    },
    download: '下載',
    tips: {
      title: '備註',
      0:'1.數據間隔選擇「全部」時: 限制天數為90',
      1:'2.數據間隔選擇「1D」、「1H」時：無天數限制',
      2:'3.任何條件下，儀器數量限制為60個',
      3:'4.檔案輸出時，輸出時間與資料多寡相關，請耐心等待',
    },
  },
  data: {
    raw: '原讀值',
    status: '狀態',
    compareDate: '對比時間差異值',
    date: {
      pick: '選擇時間',
    },
    instrument: {
      name: '儀器名稱',
    },
    value: {
      alert: '警戒值',
      action: '行動值',
      velocity1Hr: '1Hr 速率',
      velocity24Hr: '24Hr 速率',
    },
    chart: '圖表',
    measurements: '量測值',
    update: '更新時間',
    none: '暫無資料',
    refresh: '自動更新',
    onlyWaring: '顯示超過管理值',
    batt: '電量',
    remark: '備註',
    navbar: {
      all: '全部',
      alert: '警戒',
      warning: '注意',
      action: '行動',
    },
    R1: 'R1',
    R3: 'R3',
    R24: 'R24',
    day: '當日雨量',
    month: '當月雨量',
  },
  map: {
    settings: '設定',
    stations: {
      pick: '選擇測站',
    },
    contour: '等高線圖',
    instrument_id: '儀器名稱',
    orthoimagery_general : '正射影像(通用)',
    orthoimagery_mix: '正射影像(混合)',
    general_electronic_map: '臺灣通用電子地圖',
    typhon: '颱風路徑圖',
    echo: '雷達回波圖',
    rainfall: '累積雨量圖',
    earthquake: '地震報告',
    shp: '地質敏感區',
  },
  rank: {
    all_check: '全部',
    max_allow_rows: '最大數據顯示:',
    max_allow_rows_2: '筆',
    title: '儀器數據排名',
    rg: 'RG 雨量計',
    instrument_name: '儀器名稱',
    rain_value: '累積雨量(mm/日)',
    tm: 'TM 地滑計',
    tm_value: '指定時間累積變位',
    tm_total: '累積變位',
    tm_rate: '變位速率(mm/hr)',
    sti: 'STI 傾斜桿',
    sti_value: '速率(度/hr)',
    degree: '度',
  },
  project: {
    name: '專案名稱',
    project_id: '專案ID',
    location: '地址',
    start_date: '開始日期',
    client: '業主',
    contractor: '主承商',
    instrument_company: '監測廠商',
    pick: '選擇專案',
  },
  trajectory: {
    selectInstrumentGroupX: '選擇儀器組 X',
    selectInstrumentGroupY: '選擇儀器組 Y',
  },
  contact: {
    title: {
      info: '公司名稱',
      address: '地址',
      phone: '聯絡電話',
      email: '技術支援',
    },
    company: '捷貿儀器工程有限公司',
    address1: '新北市汐止區仁愛路18巷2號1樓',
  },
  pending: {
    title: '我們正在設定您的賬號。',
    subtitle: '請稍後再嘗試或聯絡我們。',
  },
  dots: {
    alert: '警戒值',
    wsl: '行動值',
    wl: '注意值',
    normal: '正常',
    down: '故障',
    heavyRain: '大雨',
    torrentialRain: '豪雨',
    superTorrentialRain: '大豪雨',
    extremeTorrentialRain: '超大豪雨',
  },
  Depth: '深度',
  Distance: '距離',
  Value: '測量值',
  datetime: '時間',
  Curve: '曲線圖',
  Compare: '比較',
  'No Data': '沒有資料',
  dashboard: {
    noneModuleType: '請選擇模塊類型',
    showModuleCount: '顯示區塊數',
    editModule: '編輯格子參數',
    save: '保存',
    updateToThisProject: '套用至此專案',
  },
  eventList: {
    AL: '警戒值',
    WSL: '行動值',
    all: '全部',
    dateTime: '查詢時間',
    selectInstrument: '選擇儀器',
    startTime: '發生時間',
    station: '測站',
    instrument: '儀器種類',
    content: '訊息內容',
    snakeLine: 'SnakeLine',

    area: '測站',
    chart: '圖表',
    notifyStaff: '通報人員',
    notifyTime: '通報時間',
    down: '儀器故障',
  },
  logType: {
    add: '新增',
    delete: '刪除',
    view: '查看',
    update: '更新',
    unauthorized: '未登入查看',
    xml: 'XML',
    line: 'line',
    all: '全部',
  },
};
export default zh;
