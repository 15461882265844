<template>
  <el-tabs v-model="selectedTab">
    <el-tab-pane
      name="commonExport"
      label="表格輸出"
      :lazy="true"
    >
      <page-export :useUrl="this.useUrl"></page-export>
    </el-tab-pane>
    <el-tab-pane
      v-if="this.enableReportExport"
      name="reportExport"
      label="報表輸出"
      :lazy="true"
    >
      <page-report />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  components: {
    PageExport: () => import('@/components/Export'),
    PageReport: () => import('@/views/Report'),
  },
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    allowedTab() {
      return this.$store.getters['project/selectedProject'].allowed_sidebar;
    },
    enableReportExport() {
      console.log('enableReportExport');
      const allowedTab = this.allowedTab;
      if([null,undefined].includes(allowedTab) || allowedTab.length == 0){
        console.log('get no allowedTab');
        return false;
      }
      console.log(allowedTab.includes('ReportExport'));
      return allowedTab.includes('ReportExport');
    },
  },
  data(){
    return {
      selectedTab: 'commonExport',
    };
  },
};
</script>
