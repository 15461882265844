<template>
  <el-form
    ref="stationForm"
    :model="form"
    :validate-on-rule-change="false"
    :hide-required-asterisk="true"
    :inline="true"
    label-position="top"
    label-width="80px"
  >
    <el-row>
      <el-form-item
        prop="area"
        label="區域"
      >
        <el-select v-model="form.area">
          <el-option
            v-for="area in areaList"
            :key="area._id"
            :value="area._id"
            :label="area.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item
        prop="station"
        label="測站"
      >
        <el-drag-select
          v-model="form.station"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="輸入並Enter新增"
        >
          <el-option
            v-for="station in stationList"
            :key="station._id"
            :label="station.name"
            :value="station._id"
          />
        </el-drag-select>
      </el-form-item>
    </el-row>
    <el-button
      :disabled="!selectedArea"
      @click="submitForm"
      type="primary"
    >
      更新區域
    </el-button>
    <el-button
      @click="updateSelectSort"
      type="primary"
    >
      更新排序
    </el-button>
  </el-form>
</template>

<script>
export default {
  props: {
    areaId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      form: {
        area: '',
        station: [],
      },
      areaList: [],
      stationList: [],
    };
  },
  computed: {
    selectedArea() {
      const selectedAreaId = this.form.area;
      const selectedArea = this.areaList.find(ele => ele._id === selectedAreaId);
      return selectedArea;
    },
    stationIdList() {
      return this.stationList.map(station => station._id);
    },
  },
  watch: {
    areaId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form.area = newValue;
      }
    },
    'form.area'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form.station = [];
        this.setAreaStation();
      }
    },
  },
  async mounted() {
    if (this.areaId) {
      this.form.area = this.areaId;
    }
    await this.getAreaList();
    await this.getStationList();
    this.setAreaStation();
  },
  methods: {
    setAreaStation() {
      if (this.selectedArea) {
        this.form.station = this.selectedArea.station;
      }
    },
    getAreaList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getAreaList', isUpdate)
        .then(res => {
          this.areaList = res;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getStationList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getStationList', isUpdate)
        .then(res => {
          this.stationList = res;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateSelectSort() {
      let message = '此操作將改變測站排序';
      const response = await this.$confirmCreate(message);
      if (response === 'confirm') {
        this.isLoading = true;
        let station = this.form.station;
        if (!station.length) {
          this.$notifyError('測站列表不能為空');
          this.isLoading = false;
          return;
        }

        try {
          await this.removeAllStationFromArea();
          await this.addStationToArea(station);

          this.$notifySuccess('修改成功');
          await this.getStationList(true);
          await this.getAreaList(true);
        } catch (error) {
          console.log(error);
          this.$notifyError(error);
        } finally {
          this.isLoading = false;
          // window.location.reload();
        }
      }
    },
    async removeAllStationFromArea() {
      return this.$axios({
        category: 'admin',
        method: 'delete',
        apiName: `area/${this.form.area}/allstation`,
        data: {
          area: [],
        },
      }).catch(this.$notifyError);
    },
    submitForm() {
      this.$refs.stationForm.validate(async valid => {
        if (valid) {
          this.updateAreaStation();
        } else {
          return false;
        }
      });
    },
    async addStationToArea(stationIdList) {
      if (!stationIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: `area/${this.form.area}/station`,
        data: {
          station: stationIdList,
        },
      }).catch(this.$notifyError);
    },
    async removeStationFromArea(stationIdList) {
      if (!stationIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'delete',
        apiName: `area/${this.form.area}/station`,
        data: {
          station: stationIdList,
        },
      }).catch(this.$notifyError);
    },
    async createAreaNewStation(stationIdList) {
      if (!stationIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: 'station',
        data: {
          area: this.form.area,
          station: stationIdList,
        },
      }).catch(this.$notifyError);
    },
    async updateAreaStation() {
      const removedStation = this.selectedArea.station.filter(station => {
        return !this.form.station.includes(station);
      });

      const newAddedStation = this.form.station.filter(station => {
        return !this.stationIdList.includes(station);
      });

      const pushedStation = this.form.station.filter(station => {
        return !this.selectedArea.station.includes(station) && this.stationIdList.includes(station);
      });

      if (!newAddedStation.length && !removedStation.length && !pushedStation.length) {
        return this.$notifyInfo('沒有改動');
      }

      let message = '此操作將';
      if (newAddedStation.length) {
        message += `新增 ${newAddedStation.length} 個測站並加至該區域\n`;
      }
      if (removedStation.length) {
        message += `從該區域移除 ${removedStation.length} 個測站\n`;
      }
      if (pushedStation.length) {
        message += `更新 ${pushedStation.length} 個測站至該區域\n`;
      }
      const response = await this.$confirmCreate(message);
      console.log(response);
      if (response === 'confirm') {
        this.isLoading = true;
        Promise.all([
          this.createAreaNewStation(newAddedStation),
          this.removeStationFromArea(removedStation),
          this.addStationToArea(pushedStation),
        ])
          .then(async () => {
            this.$notifySuccess('新增成功');
            await this.getStationList(true);
            await this.getAreaList(true);
          })
          .catch(error => {
            console.log(error);
            this.$notifyError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
