import Vue from 'vue';
import VueRouter from 'vue-router';
import { getLocalData } from '@/lib/base/localData';
import { hasAllRoles } from '@/lib/base/permission';
import { Message } from 'element-ui';

Vue.use(VueRouter);

//views
import LoginPage from '@/views/Login.vue';
import LoginTHSRPage from '@/views/Login-THSR.vue';
import LoginKMRTPage from '@/views/Login-KMRT.vue';
import Dashboard from '@/views/Dashboard.vue';
import DashboardOld from '@/views/Dashboard_old.vue';
import DataListPage from '@/views/DataList.vue';
import DataListPageForStorm from '@/views/storm/DataList.vue';
import ChartPage from '@/views/Chart.vue';
import ChartPageForStorm from '@/views/Chart.vue';
import CurvePage from '@/views/Curve.vue';
import MapPage from '@/views/Map.vue';
import LayoutPage from '@/views/Layout.vue';
import ExportPage from '@/views/Export.vue';
import ExportPageForStorm from '@/views/Export.vue';
import DetailsPage from '@/views/Details.vue';
import ContactPage from '@/views/Contact.vue';
import MonitorPage from '@/views/Monitor.vue';
//import InstrumentComputer from '@/views/InstrumentComputer.vue';
import AlertsPage from '@/views/SMS.vue';
import ClientPage from '@/views/Client.vue';

import OCCPage from '@/views/OCC.vue';
import OCCContactPage from '@/views/OCC-Contact.vue';
import InstrumentRankPage from '@/views/instrumentRank.vue';
import NoisePage from '@/views/noise.vue';
import TrajectoryPage from '@/views/Trajectory.vue';
import StormPage from '@/views/storm/index.vue';
import StormSummary from '@/views/Summary.vue';
import SnakeLinePage from '@/views/SnakeLine.vue';
import EventListPage from '@/views/storm/EventList.vue';
import AdvancedOptionsPage from '@/views/AdvancedOptions.vue';

import ManualPage from '@/views/manual/index.vue';
import ManualInstrumentPage from '@/views/manual/Instrument.vue';
import ManualEditPage from '@/views/manual/Edit.vue';
import ManualDataPage from '@/views/manual/Data.vue';
import ManualImportPage from '@/views/manual/Import.vue';
import ManualImportKMRTPage from '@/views/manual/Import-KMRT.vue';

import AdminPage from '@/views/admin/index.vue';
import AdminInstrumentPage from '@/views/admin/Instrument.vue';
import AdminInstrumentTypePage from '@/views/admin/InstrumentType.vue';
import AdminInstrumentGroupPage from '@/views/admin/InstrumentGroup.vue';
import AdminProjectPage from '@/views/admin/Project.vue';
import AdminAreaPage from '@/views/admin/Area.vue';
import AdminStationPage from '@/views/admin/Station.vue';
import AdminClientPage from '@/views/admin/Client.vue';
import AdminLayoutPage from '@/views/admin/Layout.vue';
import AdminDocumentPage from '@/views/admin/Document.vue';
import AdminRemarkPage from '@/views/admin/Remark.vue';
import AdminLogPage from '@/views/admin/Log.vue';
import AdminSMSPage from '@/views/admin/SMS.vue';
import AdminTestingPage from '@/views/admin/Testing.vue';
//import AdminReportPage from '@/views/admin/ReportSetting.vue';

import AdminEditInstrumentPage from '@/views/admin/edit/Instrument.vue';
import AdminEditOutputPage from '@/views/admin/edit/Output.vue';

import { clientVersion } from '../constants/env';

let thsrRole = clientVersion === 'thsr-main' || clientVersion === 'thsr-rain' || clientVersion === 'test';
let kmrtRole = clientVersion === 'kmrt';

import store from '@/store/index.js';
var selectedProject = store.getters['project/selectedProject'];
var first_display = selectedProject ? selectedProject.first_display : null;

const adminTable = [
  {
    path: 'edit/instrument',
    name: 'admin-instrument',
    component: AdminEditInstrumentPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'edit/output',
    name: 'admin-output',
    component: AdminEditOutputPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'instrument',
    name: 'admin-instrument',
    component: AdminInstrumentPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'instrumentType',
    name: 'admin-instrument-type',
    component: AdminInstrumentTypePage,
    meta: {
      permission: ['admin'],
    },
  },
    
  //{
  //  path: 'instrumentComputer',
  //  name: 'admin-instrument',
  //  component: InstrumentComputer,
  //  meta: {
  //    permission: ['admin'],
  //  },
  //},
  {
    path: 'instrument-group',
    name: 'admin-instrument-group',
    component: AdminInstrumentGroupPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'project',
    name: 'admin-project',
    component: AdminProjectPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'area',
    name: 'admin-area',
    component: AdminAreaPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'station',
    name: 'admin-station',
    component: AdminStationPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'user',
    name: 'admin-client',
    component: AdminClientPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'layout',
    name: 'admin-layout',
    component: AdminLayoutPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'remark',
    name: 'admin-remark',
    component: AdminRemarkPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'document',
    name: 'admin-document',
    component: AdminDocumentPage,
    meta: {
      permission: ['admin'],
    },
  },
  //{
  //  path: 'report',
  //  name: 'admin-report',
  //  component: AdminReportPage,
  //  meta: {
  //    permission: ['admin'],
  //  },
  //},
  {
    path: 'sms',
    name: 'admin-sms',
    component: AdminSMSPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'log',
    name: 'admin-log',
    component: AdminLogPage,
    meta: {
      permission: ['admin'],
    },
  },
  {
    path: 'testing',
    name: 'admin-testing',
    component: AdminTestingPage,
    meta: {
      permission: ['admin'],
    },
  },
];

const routes = [
  {
    path: '/',
    name: 'Home',
    //redirect: () => (thsrRole) ? '/slope/map' : '/data',
  },
  {
    path: '/login',
    name: 'Login',
    component: thsrRole ? LoginTHSRPage : kmrtRole ? LoginKMRTPage : LoginPage,
  },
  {
    path: '/OCC',
    name: 'OCC',
    component: OCCPage,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: thsrRole ? Dashboard : DashboardOld,
  },
  {
    path: '/noise',
    name: 'Noise',
    component: NoisePage,
  },
  {
    path: '/trajectory',
    name: 'Trajectory',
    component: TrajectoryPage,
  },
  {
    path: '/Rank',
    name: 'Rank',
    component: InstrumentRankPage,
  },
  {
    path: '/dashboard_old',
    name: 'DashboardOld',
    component: DashboardOld,
  },
  {
    path: '/summary',
    name: 'summary',
    component: () => import('@/views/Summary'),
  },
  {
    path: '/data',
    name: 'data',
    component: DataListPage,
  },
  {
    path: '/chart',
    name: 'chart',
    component: ChartPage,
  },
  {
    path: '/curve',
    name: 'curve',
    component: CurvePage,
  },
  {
    path: '/map',
    name: 'map',
    component: MapPage,
    meta: {
      mainStyle: 'is-paddingless',
    },
  },
  {
    path: '/layout',
    name: 'layout',
    component: LayoutPage,
  },
  {
    path: '/export',
    name: 'export',
    component: ExportPage,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
  },
  {
    path: '/monitor',
    name: 'monitor',
    component: MonitorPage,
  },
  {
    path: '/details',
    name: 'details',
    component: DetailsPage,
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: AlertsPage,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPage,
    meta: {
      permission: ['admin'],
    },
    children: adminTable,
  },
  {
    path: '/slope',
    name: 'slope',
    component: StormPage,
    children: [
      {
        path: 'data',
        name: 'slope-data',
        component: DataListPage,
      },
      {
        path: 'chart',
        name: 'slope-chart',
        component: ChartPage,
      },
      {
        path: 'curve',
        name: 'slope-curve',
        component: CurvePage,
      },
      {
        path: 'map',
        name: 'slope-map',
        component: MapPage,
      },
      {
        path: 'layout',
        name: 'slope-layout',
        component: LayoutPage,
      },
      {
        path: 'export',
        name: 'slope-export',
        component: ExportPage,
      },
      {
        path: 'admin',
        name: 'admin',
        component: AdminPage,
        meta: {
          permission: ['admin'],
        },
        children: [
          {
            path: 'edit/instrument',
            name: 'admin-instrument',
            component: AdminEditInstrumentPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'edit/output',
            name: 'admin-output',
            component: AdminEditOutputPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'instrument',
            name: 'admin-instrument',
            component: AdminInstrumentPage,
            meta: {
              permission: ['admin'],
            },
          },
          //{
          //  path: 'instrumentComputer',
          //  name: 'admin-instrument',
          //  component: InstrumentComputer,
          //  meta: {
          //    permission: ['admin'],
          //  },
          //},
          {
            path: 'instrument-group',
            name: 'admin-instrument-group',
            component: AdminInstrumentGroupPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'project',
            name: 'admin-project',
            component: AdminProjectPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'area',
            name: 'admin-area',
            component: AdminAreaPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'station',
            name: 'admin-station',
            component: AdminStationPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'client',
            name: 'admin-client',
            component: AdminClientPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'layout',
            name: 'admin-layout',
            component: AdminLayoutPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'remark',
            name: 'admin-remark',
            component: AdminRemarkPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'document',
            name: 'admin-document',
            component: AdminDocumentPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'sms',
            name: 'admin-sms',
            component: AdminSMSPage,
            meta: {
              permission: ['admin'],
            },
          },
          {
            path: 'log',
            name: 'admin-log',
            component: AdminLogPage,
            meta: {
              permission: ['admin'],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/storm',
    name: 'storm',
    component: StormPage,
    children: [
      {
        path: 'summary',
        name: 'storm-summary',
        component: StormSummary,
      },
      {
        path: 'data',
        name: 'storm-data',
        component: DataListPageForStorm,
        props: { useUrl: true },
      },
      {
        path: 'chart',
        name: 'storm-chart',
        component: ChartPageForStorm,
        props: { useUrl: true },
      },
      {
        path: 'snake-line',
        name: 'storm-snakeLine',
        component: SnakeLinePage,
      },
      {
        path: 'event',
        name: 'storm-eventList',
        component: EventListPage,
      },
      {
        path: 'export',
        name: 'storm-export',
        component: ExportPageForStorm,
        props: { useUrl: true },
      },
      {
        path: 'admin',
        name: 'admin',
        component: AdminPage,
        meta: {
          permission: ['admin'],
        },
        children: [
          {
            path: 'edit/instrument',
            name: 'admin-instrument',
            component: AdminEditInstrumentPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'edit/output',
            name: 'admin-output',
            component: AdminEditOutputPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'instrument',
            name: 'admin-instrument',
            component: AdminInstrumentPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          //{
          //  path: 'instrumentComputer',
          //  name: 'admin-instrument',
          //  component: InstrumentComputer,
          //  meta: {
          //    permission: ['admin'],
          //  },
          //  props: { useUrl: true },
          //},
          {
            path: 'instrument-group',
            name: 'admin-instrument-group',
            component: AdminInstrumentGroupPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'project',
            name: 'admin-project',
            component: AdminProjectPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'area',
            name: 'admin-area',
            component: AdminAreaPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'station',
            name: 'admin-station',
            component: AdminStationPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'client',
            name: 'admin-client',
            component: AdminClientPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'layout',
            name: 'admin-layout',
            component: AdminLayoutPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'remark',
            name: 'admin-remark',
            component: AdminRemarkPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'document',
            name: 'admin-document',
            component: AdminDocumentPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'sms',
            name: 'admin-sms',
            component: AdminSMSPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
          {
            path: 'log',
            name: 'admin-log',
            component: AdminLogPage,
            meta: {
              permission: ['admin'],
            },
            props: { useUrl: true },
          },
        ],
      },
    ],
  },
  {
    path: '/manual',
    name: 'manual',
    component: ManualPage,
    children: [
      {
        path: 'instrument',
        name: 'manual-instrument',
        component: ManualInstrumentPage,
      },
      {
        path: 'edit',
        name: 'manual-edit',
        component: ManualEditPage,
      },
      {
        path: 'data',
        name: 'manual-data',
        component: ManualDataPage,
      },
      {
        path: 'import',
        name: 'manual-import',
        component: ManualImportPage,
      },//ManualImportKMRTPage
      {
        path: 'import-kmrt',
        name: 'manual-import-kmrt',
        component: ManualImportKMRTPage,
      },
    ],
  },    
  {
    path: '/snake-line',
    name: 'snakeLine',
    component: SnakeLinePage,
  },
  {
    path: '/client',
    name: 'client',
    component: ClientPage,
  },
  {
    path: '/advanced',
    name: 'AdvancedOptions',
    component: AdvancedOptionsPage,
  },
  {
    path: '/OCC-Contact',
    name: 'OCC-Contact',
    component: OCCContactPage,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //  验证登入狀態
  if (getLocalData('jwt')) {
    if (to.name === 'Login') {
      window.location.href = '/';
      return false;
    } else {
      const ok = () => {
        //  validate if route need specific role permission
        selectedProject = store.getters['project/selectedProject'];
        first_display = selectedProject ? selectedProject.first_display : null;
        if (to.path === '/'){
          next(`/${first_display}`);
          return;
        }
        if (to.meta.permission) {
          if (hasAllRoles(to.meta.permission)) {
            next();
          } else {
            Message({
              message: '沒有權限查看',
              type: 'error',
            });
            if (from.name) {
              next(false);
            } else {
              window.location.href = '/';
              return false;
            }
          }
        } else {
          next();
        }
      };
      selectedProject = store.getters['project/selectedProject'];
      if (selectedProject) {
        ok();
      } else {
        store.dispatch('project/getProjectList', true).then(() => {
          ok();
        }).catch(() => {
          return false;
        });
      }
    }
  } else {
    console.log(to.name);
    if (to.name !== 'Login' && to.name !== 'client') {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
      return false;
    }
    next();
  }
});

export default router;
