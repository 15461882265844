<template>
  <div>
    <el-form
      class="columns is-multiline"
      ref="form"
      :model="form"
      :rules="rules"
      labelPosition="top"
      label-width="80px"
      :inline="true"
      :hide-required-asterisk="true"
    >
      <div class="column">
        <el-card v-loading="initLoading">
          <el-form-item>
            <label class="label" slot="label">{{ $t('project.pick') }}</label>
            <el-select
              v-model="form.project"
              filterable
              :placeholder="$t('project.pick')"
              @change="initCascaderSelector"
            >
              <el-option
                v-for="project in projectList"
                :key="project._id"
                :value="project._id"
                :label="project.project_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item required="required" prop="instrument">
            <label class="label" slot="label">
              {{ $t('chart.instrument.pick') }}
            </label>
            <!-- <el-select v-model="form.instrument" filterable @change="submitForm">
              <el-option
                v-for="instrument in instrumentList"
                :key="instrument._id"
                :label="instrument.latestVersion.name"
                :value="instrument._id"
              ></el-option>
            </el-select> -->
            <el-cascader
              v-model="form.instrument"
              style="min-width:200px"
              :options="projectOptions"
              :props="props"
              :collapse-tags="true"
              :show-all-levels="false"
              :placeholder="$t('chart.instrument.pick')"
              @change="submitForm"
            ></el-cascader>
          </el-form-item>
        </el-card>
      </div>
      <div class="column">
        <el-card>
          <el-form-item required="required" prop="date">
            <div class="flex-row align-center justify-between" style="height:50px">
              <label class="label" slot="label">{{ $t('chart.date.pick') }}</label>
              <div class="is-pulled-right">
                <el-button
                  size="mini"
                  type="primary"
                  :class="{ 'is-plain': dateActiveIndex !== 0 }"
                  @click="setDateActiveIndex(0)"
                  >1D</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  :class="{ 'is-plain': dateActiveIndex !== 1 }"
                  @click="setDateActiveIndex(1)"
                  >7D</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  :class="{ 'is-plain': dateActiveIndex !== 2 }"
                  @click="setDateActiveIndex(2)"
                  >1M</el-button
                >
              </div>
            </div>
            <el-date-picker
              v-model="form.date"
              type="datetimerange"
              :start-placeholder="$t('chart.date.start')"
              :end-placeholder="$t('chart.date.end')"
              :default-time="['00:00:00']"
              format="yyyy/MM/dd HH:mm"
              @change="submitForm"
            ></el-date-picker>
          </el-form-item>
        </el-card>
      </div>
      <div class="column">
        <el-card>
          <el-form-item>
            <label class="label" slot="label">{{
              $t("chart.interval.pick")
            }}</label>
            <el-radio-group v-model="form.interval" @change="submitForm">
              <el-radio-button
                v-for="interval in intervalList"
                :label="interval"
                :key="interval"
                >{{ $t(`chart.interval.${interval}`) }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-button
            @click="dialogVisible=true"
          >
            新增1000列
          </el-button>
        </el-card>
      </div>
    </el-form>
    <el-card v-if="hadSubmitted" v-loading="tableLoading">
      <div class="flex-column">
        <div class="flex-row justify-end">
          <hot-table
            id="hot-container"
            root="hot"
            ref="hot"
            :settings="settings"
          ></hot-table>
        </div>
      </div>
    </el-card>
    <el-dialog :visible="dialogVisible" @open="openDialog" @close="closeDialog">
      <span>請選擇新增對象儀器</span>
      <el-select v-model="addRowInstrument" placeholder="请选择">
        <el-option
          v-for="item in addRowInstrumentOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRow(addRowInstrument)">確 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
// import { MessageBox } from 'element-ui'
window.moment = moment;
MomentTimeZone();

export default {
  components: {
    HotTable,
  },
  data() {
    return {
      hadSubmitted: false,
      initLoading: false,
      tableLoading: false,
      isLoading: true,
      lastMove: null,
      keyList: [],
      projectOptions: [],
      // instrumentList: [],
      instrumentMap: {},
      instrumentList: [],
      $_instrumentList: [],
      multipleSelection: [],
      dataList: [],
      form: {
        instrument: [],
        date: [
          moment()
            .subtract(2, 'h')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
        interval: 'all',
        project: '',
        selectedArea: '',
        selectedStation: '',
      },
      props: {
        multiple: true,
        //expandTrigger: 'hover',
        label: 'label',
        value: 'value',
        children: 'children',
      },
      dateActiveIndex: 1,
      intervalList: ['quarter', 'daily', 'all'],
      chartDateRangeList: [
        {
          name: '6H',
          day: null,
          hours: 6,
        },
        {
          name: '1D',
          day: 1,
          hours: null,
        },
        {
          name: '7D',
          day: 7,
          hours: null,
        },
      ],
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      timezone: moment.tz.guess(),
      rules: {
        instrument: [
          {
            type: 'array',
            // type: 'string',
            required: true,
            message: this.$t('chart.error.instrument'),
            trigger: 'change',
          },
        ],
        date: [
          {
            type: 'array',
            required: true,
            message: this.$t('chart.error.date'),
            trigger: 'change',
          },
        ],
      },
      dialogVisible: false,
      addRowInstrument: null,
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    settings() {
      const vm = this;
      return {
        data: this.dataList,
        colHeaders: ['時間', '儀器名稱', '_id', 'reading1', 'reading2'],
        colWidths: 250,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        undo: true,
        copyPaste: {
          columnsLimit: 2000,
          rowsLimit: 100000,
        },
        columns: [
          {
            data: 'TIMESTAMP',
            //readOnly: true,
          },
          {
            data: 'instrumentName',
            readOnly: true,
          },
          {
            data: '_id',
            readOnly: true,
          },
          {
            data: 'reading1',
          },
          {
            data: 'reading2',
          },
        ],
        hiddenColumns: {
          columns: [2],
          indicators: true,
        },
        // beforeChange: function(changes, source) {
        //   console.log(MessageBox)
        //   console.log(vm)
        //   return false
        // },
        beforeUndo(action) {
          if (action.actionType === 'remove_row') vm.getOutput();
        },
        beforeRemoveRow(index, amount, physicalRows) {
          console.log('beforeRemoveRow -> physicalRows', physicalRows);
          const dataList = this.getSourceData();
          // await MessageBox.confirm(
          //   `將刪除${amount}筆資料，資料不能復原，是否確認刪除？`,
          //   '提示',
          //   {
          //     confirmButtonText: '确定',
          //     cancelButtonText: '取消',
          //     type: 'warning'
          //   }
          // )
          //   .then(() => {
          vm.setLastMove('delete');
          const deleteList = physicalRows.map(ele => {
            return dataList[ele]._id;
          });

          vm.deleteOutput(deleteList);
          //   })
          //   .catch(error => {
          //     if (error === 'cancel') {
          //       vm.$notifyInfo('已取消刪除')
          //     }
          //     this.undo()
          //     return false
          //   })
        },
        beforeChange(changes, source) {
          const dataList = this.getData();
          const updates = changes.map(([row, prop, oldValue, newValue]) => {
            console.log(row);
            console.log(prop);
            let ins_target = vm.instrumentList.find(ele=>{
              return ele.latestVersion.name == dataList[row][1];
            });
            console.log(ins_target._id);
            //console.log(vm.form.instrument[row][2]);
            if(prop=='TIMESTAMP'){
              return {
                //_id: dataList[row][2],
                'TIMESTAMP': new Date(newValue),
                instrument_id: ins_target._id,
                reading1: dataList[row][3],
                reading2: dataList[row][4],
              };
            }
            return {
              _id: dataList[row][2],
              [prop]: Number(newValue),
            };
          });
          vm.updateOutput(updates, source).then(()=>{
            console.log('finish update');
            vm.getOutput();
          });
          // if (source === 'edit') {
          //   // const message = `將更新${changes.length}筆資料, 是否繼續？`;
          //   // const response = await vm.$confirmEdit(message);
          //   // if (response === 'confirm') {
          //   vm.updateOutput(updates, source);
          //   // } else {
          //   // vm.resetInstrumentList();
          //   // }
          // } else if (source === 'UndoRedo.undo') {
          //   vm.updateOutput(updates, source);
          // }
        },
        // beforeChange(changes, source) {
        //   if (source === 'UndoRedo.undo' && vm.lastMove !== 'update') {
        //     vm.setLastMove(source);
        //     return;
        //   }
        //   const dataList = this.getData();
        //   console.log('settings -> dataList', dataList);
        //   if (changes) {
        //     if (vm.lastMove === 'update' && source !== 'edit') {
        //       vm.setLastMove('undo update');
        //       const updates = changes.map(([row, prop, oldValue, newValue]) => {
        //         return {
        //           _id: dataList[row][1],
        //           [prop]: Number(newValue),
        //         };
        //       });
        //       vm.updateOutput(updates, 'undo');
        //     } else {
        //       // await MessageBox.confirm(
        //       //   `將更新${changes.length}筆資料, 是否繼續？`,
        //       //   '提示',
        //       //   {
        //       //     confirmButtonText: '确定',
        //       //     cancelButtonText: '取消',
        //       //     type: 'warning'
        //       //   }
        //       // )
        //       //   .then(() => {
        //       vm.setLastMove('update');
        //       const updates = changes.map(([row, prop, oldValue, newValue]) => {
        //         return {
        //           _id: dataList[row][1],
        //           [prop]: Number(newValue),
        //         };
        //       });
        //       console.log(updates);
        //       vm.updateOutput(updates, 'edit');
        //       // })
        //       // .catch(error => {
        //       //   if (error === 'cancel') {
        //       //     vm.$notifyInfo('已取消更新')
        //       //   }
        //       //   this.undo()
        //       //   return false
        //       // })
        //     }
        //   }
        // },
        contextMenu: {
          items: {
            separator: Handsontable.plugins.ContextMenu.SEPARATOR,
            copy: {
              name: '复制',
            },
            undo: {
              name: '撤消',
            },
            separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
            remove_row: {
              name: '删除行',
            },
          },
        },
        filters: true,
        // dropdownMenu: true,
        dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
        licenseKey: 'non-commercial-and-evaluation',
      };
    },
    addRowInstrumentOptions() {
      let result = [];
      //console.log(this.form.instrument);
      //console.log(this.instrumentList);
      result = this.form.instrument.map(ins=>{
        let ins_id = ins[2];
        let ins_target = this.instrumentList.find(ele=>{
          return ele._id == ins_id;
        });
        return {
          value: ins_target._id,
          label: ins_target.latestVersion.name,
        };
      });
      return result;
    },
  },
  mounted() {
    this.form.project = this.selectedProject._id;
    // this.getInstrumentList();
    this.initCascaderSelector();
  },
  methods: {
    initCascaderSelector() {
      this.projectOptions = [];
      this.form.instrument = [];
      this.dataList = [];
      this.updateTable();
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.form.project}/area-station-instrument`,
      })
        .then(res => {
          this.projectOptions = res.data.area.map(area => {
            const obj = {
              label: area.name,
              disabled: area.station.length === 0,
              children: area.station.map(station => {
                const obj = {
                  label: station.name,
                  disabled: station.instrument.length === 0,
                  children: station.instrument.map(instrument => {
                    const obj = {
                      label: instrument.latestVersion.name,
                      value: instrument._id,
                    };
                    this.instrumentList.push(instrument);
                    this.instrumentMap[instrument._id] = instrument;
                    return obj;
                  }),
                };
                return obj;
              }),
            };
            return obj;
          });
        })
        .then(() => {
          this.initLoading = false;
        })
        .catch(error => {
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        });
    },
    getInstrumentList(isUpdate = false) {
      this.hasSubmitted = true;
      this.isLoading = true;
      return this.$store
        .dispatch('data/getProjectInstrumentListWithLatestVersion', {
          projectId: this.form.project,
          isUpdate,
        })
        .then(res => {
          this.instrumentList = res;
          this.$_instrumentList = this.$lodash.cloneDeep(this.instrumentList);
          if (!this.form.instrument) {
            this.form.instrument = this.instrumentList[0]._id;
            this.submitForm();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getProjectInstruments() {
      await this.$axios
        .$get(`/project/${this.form.project}/area`)
        .then(res => {
          this.instrumentList = res.area.map(area => {
            const obj = {
              label: area.name,
              disabled: area.station.length === 0,
              children: area.station.map(station => {
                const obj = {
                  label: station.name,
                  disabled: station.instrument.length === 0,
                  children: station.instrument.map(instrument => {
                    const obj = {
                      label: instrument.data[0].name,
                      value: instrument._id,
                    };
                    this.instrumentList.push(instrument);
                    return obj;
                  }),
                };
                return obj;
              }),
            };
            return obj;
          });
        })
        // .then(() => {
        //   const instrumentId = this.$route.params.id
        //   if (
        //     instrumentId &&
        //     this.instrumentList.some(ele => {
        //       return ele._id === instrumentId
        //     })
        //   ) {
        //     this.form.instrument.push([undefined, undefined, instrumentId])
        //     this.submitForm()
        //   }
        // })
        .catch(error => {
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        })
        .finally(() => {
          this.initLoading = false;
        });
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getOutput();
        } else {
          return false;
        }
      });
    },
    strictFilterInstrumentList() {
      const instrumentList = [];
      this.form.instrument.forEach(ele => {
        ele.forEach(instrument => {
          if (instrument && instrument.trim().length && !instrumentList.includes(instrument))
            instrumentList.push(instrument);
        });
      });
      return instrumentList;
    },
    async getOutput() {
      const instrumentList = this.strictFilterInstrumentList();
      if (this.form.instrument.length <= 0) return;
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.form.project}/output?type=${this.form.interval}`,
        data: {
          date: this.form.date,
          interval: this.form.interval,
          // instrument: [this.form.instrument],
          instrument: instrumentList,
          timezone: this.timezone,
        },
      })
        .then(res => {
          if (!res.data.length) {
            this.$notifyInfo('該時段內沒有資料');
          }
          this.dataList = res.data;
          this.dataList.forEach(ele => {
            ele.TIMESTAMP = moment(ele.TIMESTAMP).format('YYYY/MM/DD HH:mm');
            ele.instrumentName = this.instrumentMap[ele.instrument_id].latestVersion.name;
          });

          this.hadSubmitted = true;
          
          console.log('finish get output');
          this.updateTable();
        })
        .catch(this.$notifyError);
    },
    setLastMove(type) {
      this.lastMove = type;
    },
    async updateOutput(updateList, mode = 'edit') {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'put',
        apiName: 'output/bulk',
        data: updateList,
      })
        .then(() => {
          if (mode === 'UndoRedo.undo') {
            this.$notifyInfo('資料已復原');
          } else {
            this.$notifySuccess('資料已更新');
          }
          // this.submitForm();
          // this.getInstrumentList(true);
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    async deleteOutput(deleteList) {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'delete',
        apiName: 'output/bulk',
        data: deleteList,
      })
        .then(async res => {
          this.$notifySuccess('已成功刪除');
          console.log(this.form.instrument);
          this.updateInstrumentLatestOutput(this.form.instrument[0][2]);
        })
        .catch(err => {
          this.$notifyError(err.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateInstrumentLatestOutput(instrumentId) {
      console.log(instrumentId);
      return this.$axios({
        category: 'admin',
        method: 'put',
        apiName: `instrument/${instrumentId}/output/last`,
      });
    },
    setDateActiveIndex(index) {
      this.dateActiveIndex = index;
      const dateRange = [
        [
          moment()
            .startOf('day')
            .subtract(1, 'd')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
        [
          moment()
            .startOf('day')
            .subtract(7, 'd')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
        [
          moment()
            .startOf('day')
            .subtract(1, 'month')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
      ];
      this.form.date = dateRange[index];
      this.submitForm();
    },
    dateFormat(row, column) {
      const date = row[column.property];
      if (!date) {
        return '';
      }
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
    updateTable() {
      if(this.hadSubmitted)
        setTimeout(() => {
          this.$refs.hot.hotInstance.loadData(this.dataList);
        }, 100);
    },
    addRow(instrument_id, addCount = 1000) {
      console.log(instrument_id);
      this.dialogVisible = false;
      let ins_target = this.instrumentList.find(ele=>{
        return ele._id == instrument_id;
      });
      for(let i = 0;i<addCount;i++){
        this.dataList.push({
          'TIMESTAMP': '',
          'instrumentName': ins_target.latestVersion.name,
          'reading1': '',
          'reading2': '',
        });
      }
    },
    openDialog() {
      //console.log('openDialog');
      this.$forceUpdate();
      this.addRowInstrument = this.form.instrument[0][2];
    },
    closeDialog() {
      //console.log('closeDialog');
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.hot-container
  overflow-y auto
</style>
