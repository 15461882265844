<template>
  <div v-loading="typeLoading || instrumentLoading || groupLoading">
    <!-- 上傳 .xlsx 檔案按鈕 -->
    <div class="button-container">
      <el-upload
        :show-file-list="false"
        accept=".xlsx"
        :before-upload="handleFileUpload"
      >
        <el-button type="primary">上傳 XLSX 檔案</el-button>
      </el-upload>
      <el-button @click="addRow">新增行</el-button>
      <el-button @click="dialogVisible = true">上傳</el-button>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <span>確定上傳?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="uploadRow()">上傳</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 列表顯示區 -->
    <el-table 
      :data="rows" 
      style="width: 100%; margin-top: 20px; " 
      border
    >
      <el-table-column label="分頁選單" width="180">
        <template #default="scope">
          <el-select v-model="scope.row.sheetName" @change="getInstrumentData(scope.row.sheetName)" placeholder="選擇分頁">
            <el-option
              v-for="sheet in sheetNames"
              :key="sheet"
              :label="sheet"
              :value="sheet"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="時間" width="140">
        <template #default="scope">
          {{ scope.row.timestamp }}
        </template>
      </el-table-column>
      <el-table-column label="內容">
        <template #default="scope">
          <div v-if="scope.row.group && scope.row.datas.length>0">
            <el-table :data="scope.row.datas" border style="width: 100%" size="mini">
              <!--el-table-column
                v-for="(value, key) in Object.keys(scope.row.datas[0])"
                :key="key"
                :label="value"
                :prop="value"
              >
              </el-table-column-->
              <el-table-column
                prop="depth"
                label="深度(m)"
              >
              </el-table-column>
              <el-table-column
                prop="初始值(測量值(+A))"
                label="初始值(測量值(+A))"
              >
              </el-table-column>
              <el-table-column
                prop="初始值(測量值(-A))"
                label="初始值(測量值(-A))"
              >
              </el-table-column>
              <el-table-column
                prop="量測值(測量值(+A))"
                label="量測值(測量值(+A))"
              >
              </el-table-column>
              <el-table-column
                prop="量測值(測量值(-A))"
                label="量測值(測量值(-A))"
              >
              </el-table-column>
              <el-table-column
                prop="位移量(mm)"
                label="位移量(mm)"
              >
              </el-table-column>
            </el-table>
          </div>
          <div v-else-if="scope.row.datas.length>0">
            <el-table :data="scope.row.datas" border style="width: 100%" size="mini">
              <el-table-column
                v-for="(value, key) in Object.keys(scope.row.datas[0])"
                :key="key"
                :label="value"
              >
                <template #default="scope">
                  {{ scope.row[value][0].data }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="儀器" width="200">
        <template #default="scope">
          <div v-for="(value, key) in scope.row.instruments" :key="key">
            {{ value ? value.data[0].name : "-" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="人工量測數據" width="200">
        <template #default="scope">
          <table>
            <tbody>
              <tr v-for="(value, key) in scope.row.reading" :key="key">
                {{ value ? (`${value['A+'] ? `${value['A+']} / ${value['A-']}` : value.reading1 ? value.reading1 : '-'} / ${value.reading3 ? value.reading3 : '-'}`) : "-" }}
              </tr>
            </tbody>
          </table>
        </template>
      </el-table-column>
      <!--el-table-column label="操作" width="100">
        <template #default="scope">
          <el-button @click="uploadRow(scope.row)">上傳</el-button>
        </template>
      </el-table-column-->
    </el-table>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
const ExcelJS = require('exceljs');

export default {
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  data() {
    return {
      dialogVisible: false,
      sheetNames: [], // 存放 xlsx 文件中的所有分頁名稱
      rows: [], // 列表，預設有一行
      originInstrumentList: [],
      stationsList: [],
      instrumentTypeList: [],
      workbook: null,
      instrumentLoading: false,
      typeLoading: false,
      groupLoading: false,
      sheet_printArea: {},
      instrumentGroupList: [],
    };
  },
  mounted(){
    this.getGroup();
    this.getInstrumentTypeList();
    this.getInstruments();
  },
  methods: {
    // 用於創建一個空白行
    createEmptyRow() {
      return {
        text: '', // 預設空白文字
        sheetName: '', // 預設沒有選擇分頁
        datas: [],
        timestamp: '',
        station: null,
        instruments: [],
        reading: [],
      };
    },
    // 處理文件上傳
    handleFileUpload(file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.$notifySuccess('載入成功');
        const workbook_2 = new ExcelJS.Workbook();
        await workbook_2.xlsx.load(e.target.result);
        workbook_2.eachSheet((worksheet, sheetId) => {
          const printArea = worksheet.pageSetup.printArea;
        
          //console.log(`Sheet: ${worksheet.name}`);
          if (printArea) {
            //console.log(`列印範圍: ${printArea}`);
            this.sheet_printArea[worksheet.name] = printArea;
          } else {
            //console.log('沒有設置列印範圍');
          }
        });

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array', cellStyles: true });
        const sheetsInfo = workbook.Workbook.Sheets;
        this.sheetNames = workbook.SheetNames.filter(sheetName => {
          //const sheet = workbook.Sheets[sheetName];
          const sheet = sheetsInfo.find(info => info.name === sheetName);
          return sheet && sheet.Hidden == 0; // 檢查是否為隱藏分頁
        });
        this.workbook = workbook;
        this.autoLoadAllTable();
      };
      reader.readAsArrayBuffer(file);
      return false; // 阻止默認的上傳行為
    },
    // 新增一行至列表
    addRow() {
      this.rows.push(this.createEmptyRow());
    },
    autoLoadAllTable(){
      let rowCount = -1;
      let rowCounter = 0;
      for(let sheet of this.sheetNames){
        if(rowCount<rowCounter){
          this.addRow();
          rowCount++;
        }
        this.rows[rowCount].sheetName = sheet;
        this.getInstrumentData(sheet);
        let row = this.rows[rowCount];
        if(row.station){
          rowCounter++;
        }
        else{
          this.rows[rowCount] = this.createEmptyRow();
        }
      }
    },
    // 上傳行內容
    uploadRow() {
      this.dialogVisible = false;
      // 在這裡可以進行後端上傳或其他操作
      let data = this.rows.map(ele=>{
        return {
          timestamp: ele.timestamp,
          instrument_data: ele.reading.filter(reading=>reading),
        };
      }).filter(ele=>ele.timestamp);

      console.log(data);

      this.$axios({
        category: 'manual',
        method: 'put',
        apiName: 'importManualOutput',
        data,
      }).then(this.$notifySuccess('上傳成功'));
    },
    // 讀取所有儀器類型
    async getInstrumentTypeList(){
      this.typeLoading = true;
      const res = await this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'instrument_type',
      });
      this.instrumentTypeList = res.data.filter(ele => ele.manual).map(ele=>{
        return {
          ...ele,
          value: ele.name,
          label: ele.name,
        };
      });
      this.typeLoading = false;

      console.log(this.instrumentTypeList);
    },
    // 讀取所有人工量測儀器
    async getInstruments(){
      this.instrumentLoading = true;
      const res = await this.$axios({
        category: 'manual',
        method: 'get',
        apiName: `getManualInstrument/${this.selectedProject._id}`,
      });
      this.originInstrumentList = res.data;
      this.stationsList = this.originInstrumentList.reduce((pre, cur)=>{
        let stationIndex = pre.findIndex(ele=>ele.station==cur.station);
        if(stationIndex >= 0){
          pre[stationIndex].instruments.push(cur);
        }
        else{
          pre.push({
            station: cur.station,
            instruments: [cur],
          });
        }
        return pre;
      },[]);
      //this.getInstrumentsData();
      console.log(this.stationsList);
      this.instrumentLoading = false;

      console.log(this.originInstrumentList);
    },
    async getGroup(){
      this.groupLoading = true;
      let res = await this.$store
        .dispatch('data/getProjectInstrumentGroupList', {
          projectId: this.selectedProject._id,
        });
      this.instrumentGroupList = res;
      this.groupLoading = false;
    },
    getInstrumentData(sheetName){
      console.log(sheetName);
      let rowindex = this.rows.findIndex(ele=>ele.sheetName === sheetName);
      const sheet = this.workbook.Sheets[sheetName];

      // 將分頁內容轉換為 JSON 格式
      let sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      let hiddenColumns = [];
      let hiddenRows = [];

      //console.log(sheetData);
      // 檢查合併的格
      if (sheet['!merges']) {
        //console.log(this.$lodash.cloneDeep(sheet['!merges']));
        for(let merges of sheet['!merges']){
          let merges_data = sheetData[merges.s.r][merges.s.c];
          //console.log(merges.s.r, merges.s.c, merges.e.r, merges.e.c);
          //console.log(merges_data);
          if(merges_data){
            for(let row = merges.s.r; row <= merges.e.r; row++){
              //console.log(sheetData[row]);
              for(let col = merges.s.c; col <= merges.e.c; col++)
              {
                if(sheetData[row])sheetData[row][col] = merges_data;
              }
              //console.log(sheetData[row]);
            }
          }
        }
      }

      // 檢查隱藏的欄
      if (sheet['!cols']) {
        hiddenColumns = sheet['!cols']
          .map((col, index) => (col && col.hidden ? index : -1))
          .filter(index => index !== -1); // 僅保留隱藏欄的索引
      }

      // 檢查隱藏的列
      if (sheet['!rows']) {
        hiddenRows = sheet['!rows']
          .map((row, index) => (row && row.hidden ? index : -1))
          .filter(index => index !== -1); // 僅保留隱藏列的索引
      }
      
      console.log(`get sheet print area: ${this.sheet_printArea[sheetName]}`);
      let print_area = this.parsePrintArea(this.sheet_printArea[sheetName]);

      //console.log(print_area);
      //console.log(sheetData);

      sheetData = sheetData.filter((ele, index)=>{
        return !hiddenRows.includes(index) && index < print_area.bottom;
      });
      
      sheetData = sheetData.map(ele=>ele.reduce((pre,cur,curindex)=>{
        if(curindex < print_area.right) pre[curindex] = cur;
        return pre;
      },[]));

      //console.log(sheetData);

      let starter = 0;
      let headerIndex = sheetData.findIndex(ele=>{
        return ['編號','儀器編號'].includes(ele[starter]);
      });

      if(headerIndex<0){
        starter = 1;
        headerIndex = sheetData.findIndex(ele=>{
          return ['編號','儀器編號'].includes(ele[starter]);
        });
      }

      if(headerIndex >= 0){
        let header = [];
        let no_header = [];
        if(headerIndex > 0)
        {
          let count = 0;
          header = sheetData[headerIndex].reduce((pre, ele, index)=>{
            //console.log(index);
            let result;
            if(index === starter) {
              result = {
                main: ele,
                sub: sheetData[headerIndex + 1][index],
              };
            }
            else result = {
              main: sheetData[headerIndex - 1][index] ?? ele,
              sub: sheetData[headerIndex + 1][index],
            };
            if(!result) no_header.push(index);
            else pre[index] = result;
            return pre;
          },[]);
        }
        else if(headerIndex == 0){
          header = sheetData[headerIndex].reduce((pre, cur, curindex)=>{
            pre[curindex] = {
              main: cur,
            };
            return pre;
          },[]);
        }

        let timestamp = this.trytomatchTimestamp(sheetData, starter);

        console.log(headerIndex);
        console.log(header);
        console.log(no_header);

        let datas = [];
        for(let index = headerIndex + 1;index<sheetData.length;index++){
          //console.log(sheetData[index]);
          //if((sheetData[index]?.length ?? 0) < header.length) continue;
          console.log(sheetData[index]);
          let row = sheetData[index].reduce((pre,ele,index)=>{
            if(!no_header.includes(index)) pre[index] = ele;
            return pre;
          },[]);
          console.log(row);
          datas.push(header.reduce((pre,cur,curindex)=>{
            //console.log(pre,cur,curindex+starter);
            //Number(row[curindex])?.toFixed(4)
            let data = Number(row[curindex])?.toFixed(4);
            if(isNaN(data)){
              data = row[curindex];
            }

            let list = [];
            if(pre[cur.main]) list = pre[cur.main];
            list.push({
              data,
              subheader: cur.sub,
            });
            pre[cur.main] = list;
            
            return pre;
          },{}));
        }
        datas = datas.filter(ele=>{
          let keys = Object.keys(ele);
          let index = keys.indexOf('編號');
          if(index<0) index = keys.indexOf('儀器編號');
          if(index<0) return false;
          if(!ele[keys[index]] && !ele[keys[index]].data?.[0]) return false;
          return true;
        });

        //console.log(sheetData);
        console.log(timestamp);
        //console.log(datas);

        this.rows[rowindex].timestamp = this.convertExcelDate(timestamp);
        this.rows[rowindex].datas = datas;

        this.rows[rowindex].station = this.trytomatchStation(sheetName);
        if(this.rows[rowindex].station){
          this.rows[rowindex].instruments = this.trytomatchInstrument(datas, this.rows[rowindex].station);
          console.log(this.rows[rowindex].station);
          console.log(datas);
          console.log(this.rows[rowindex].instruments);
          this.rows[rowindex].instrument_type = this.rows[rowindex].instruments?.find(ele=>ele)?.instrument_type;
          console.log('get instrument type',this.rows[rowindex].instrument_type);
          this.rows[rowindex].reading = this.rows[rowindex].instruments.map(ins=>{
            if(!ins) return null;
            let reading1_tag = this.rows[rowindex].instrument_type?.reading1_tag;
            let reading3_tag = this.rows[rowindex].instrument_type?.reading3_tag;
            let reading1_subtag = this.rows[rowindex].instrument_type?.reading1_subtag;
            let reading3_subtag = this.rows[rowindex].instrument_type?.reading3_subtag;
            let reading1_data = ins.xlsx_data[reading1_tag];
            let reading3_data = ins.xlsx_data[reading3_tag];
            let reading1_sub_index = reading1_subtag ? reading1_data?.findIndex(ele=>ele.subheader===reading1_subtag) : 0;
            let reading3_sub_index = reading3_subtag ? reading3_data?.findIndex(ele=>ele.subheader===reading3_subtag) : 0;
            
            let reading1 = reading1_data?.[reading1_sub_index]?.data;
            let reading3 = reading3_data?.[reading3_sub_index]?.data;
            reading1 = isNaN(Number(reading1)) ? NaN : reading1;
            reading3 = isNaN(Number(reading3)) ? NaN : reading3;
            return {
              instrument: ins,
              reading1,
              reading3,
            };
          });
        }
      }
      else{
        this.getInstrumentGroupData(sheetName, sheetData, print_area);
      }

      console.log(this.rows);
      this.$forceUpdate();
    },
    convertExcelDate(excelDate) {
      // Excel 日期的起始時間是 1900-01-01
      const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel 日期從 1900-01-01 開始，但包含閏年錯誤
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const date = new Date(excelEpoch.getTime() + excelDate * millisecondsPerDay);

      // 格式化為 YYYY-MM-DD HH:mm
      return date.toISOString().slice(0, 16).replace('T', ' ');
    },
    trytomatchTimestamp(sheetData, starter) {
      return (sheetData.find(ele=>{
        if(!ele[starter]) return false;
        if(ele[starter] && ((ele[starter].toString()).includes('本次觀測日期') || (ele[starter].toString()).includes('觀測日期'))) return true;
        if(['TIMESTAMP','timestamp'].includes(ele[starter])) return true;
        return false;
      }))?.[starter+1];
    },
    trytomatchStation(sheetName) {
      const result = sheetName.replace(/\s+/g, '');
      for(let stationData of this.stationsList){
        //console.log(`${sheetName}-${stationData.station}`);
        if(result == stationData.station) return stationData;
      }
      console.log(`get no station name: ${result}`);
      return false;
    },
    trytomatchGroup(sheetName) {
      const result = sheetName.replace(/\s+/g, '');
      for(let group of this.instrumentGroupList){
        console.log(group);
        if(result == group.name) return group;
      }
      console.log(`get no group name: ${result}`);
      return false;
    },
    trytomatchInstrument(datas, station){
      let instruments = [];
      for(let data of datas){
        let ins_index = station.instruments.findIndex(ele=>ele.data[0].name === data['編號']?.[0]?.data || ele.data[0].name === data['儀器編號']?.[0]?.data);
        if(ins_index >= 0){
          instruments.push({
            ...station.instruments[ins_index],
            xlsx_data: data,
          });
        }
        else{
          instruments.push(null);
        }
      }
      return instruments;
    },
    parsePrintArea(printArea) {
      if (!printArea || typeof printArea !== 'string') {
        throw new Error('Invalid print area');
      }
    
      // 拆解範圍，例如 "A1:B2"
      const [startCell, endCell] = printArea.split(':');
    
      // 解析單一儲存格的列和行
      const parseCell = (cell) => {
        const match = cell.match(/^([A-Z]+)(\d+)$/); // 解析格式，例如 "A1"
        if (!match) {
          throw new Error(`Invalid cell format: ${cell}`);
        }
      
        const column = match[1];
        const row = parseInt(match[2], 10);
      
        // 將列從字母轉為數字 (例如 A = 1, B = 2, ..., Z = 26, AA = 27)
        const columnNumber = column.split('').reduce((acc, char) => {
          return acc * 26 + (char.charCodeAt(0) - 64); // A 的 ASCII 為 65
        }, 0);
      
        return { column: columnNumber, row };
      };
    
      // 解析起始與結束儲存格
      const start = parseCell(startCell);
      const end = parseCell(endCell);
    
      return {
        left: start.column,
        right: end.column,
        top: start.row,
        bottom: end.row,
      };
    },
    getInstrumentGroupData(sheetName, sheetData, print_area){
      let rowindex = this.rows.findIndex(ele=>ele.sheetName === sheetName);
      let starter = print_area.left - 1;
      let headerIndex = sheetData.findIndex(ele=>{
        return ['深度'].includes(ele[starter]);
      });
      console.log(`try to match instrument group ${sheetName}`);
      if(headerIndex>=0) console.log('sheet-', sheetName, 'found "深度" in', headerIndex);
      else return;

      let sub_headerIndex = headerIndex + 1;
      if(!['m'].includes(sheetData[sub_headerIndex][starter])) {
        console.log('sheet-', sheetName, 'cannot find sub header', sheetData[sub_headerIndex][starter]);
        return;
      }

      let header = sheetData[headerIndex].reduce((pre, cur, curindex)=>{
        pre[curindex] = `${cur}(${sheetData[sub_headerIndex][curindex]})`;
        return pre;
      },[]);
      console.log(header);
      let datas = [];
      for(let i = sub_headerIndex + 1; i < sheetData.length; i++){
        let depth = Number(sheetData[i][starter]);
        if(isNaN(depth)) break;
        //let data = sheetData[i].slice(starter + 1, starter + 1 + 6);
        datas.push({
          depth,
          ...(header.reduce((pre, cur, curindex)=>{
            pre[cur] = sheetData[i][curindex];
            return pre;
          },{})),
        });
        console.log('get depth',depth,'data:', datas[datas.length-1]);
      }

      let timestamp = this.trytomatchTimestamp(sheetData, starter);

      if(!timestamp) timestamp = this.trytomatchTimestamp(sheetData, starter+1);

      this.rows[rowindex].timestamp = this.convertExcelDate(timestamp);
      this.rows[rowindex].datas = datas;

      this.rows[rowindex].group = this.trytomatchGroup(sheetName);
      this.rows[rowindex].station = this.trytomatchStation(sheetName);
      
      this.rows[rowindex].instruments = this.rows[rowindex].group && this.rows[rowindex].group.instrumentList.map(ele=>{
        let instrument = this.originInstrumentList.find(ins=>ins._id.toString() === ele.instrument.toString());
        if(!instrument) return undefined;
        let depth_data = datas.find(row=>row.depth===ele.depth);
        return {
          ...instrument,
          depth_data,
        };
      });
      //console.log(this.rows[rowindex]);
      this.rows[rowindex].reading = this.rows[rowindex].group && this.rows[rowindex].instruments.map(ins=>{
        if(!ins) return null;
        let reading1_A_plus_tag = ins.instrument_type?.['reading1_A+_tag'];
        let reading1_A_minus_tag = ins.instrument_type?.['reading1_A-_tag'];
        let reading3_tag = ins.instrument_type?.reading3_tag;
        //let reading1_subtag = this.rows[rowindex].instrument_type?.reading1_subtag;
        //let reading3_subtag = this.rows[rowindex].instrument_type?.reading3_subtag;
        let reading1_A_plus = ins.depth_data[reading1_A_plus_tag];
        let reading1_A_minus = ins.depth_data[reading1_A_minus_tag];
        let reading3 = ins.depth_data[reading3_tag];
        //console.log('reading1_A_plus_tag',reading1_A_plus_tag);
        //console.log('reading1_A_minus_tag',reading1_A_minus_tag);
        //console.log('reading3_tag',reading3_tag);
        //console.log('depth_data',ins.depth_data);
        //console.log('reading1_A_plus',reading1_A_plus);
        //console.log('reading1_A_minus',reading1_A_minus);
        //console.log('reading3',reading3);
        reading1_A_plus = isNaN(Number(reading1_A_plus)) ? NaN : reading1_A_plus;
        reading1_A_minus = isNaN(Number(reading1_A_minus)) ? NaN : reading1_A_minus;
        reading3 = isNaN(Number(reading3)) ? NaN : reading3;
        return {
          instrument: ins,
          'A+': reading1_A_plus,
          'A-': reading1_A_minus,
          reading3,
        };
      });
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex; /* 啟用 Flexbox */
  align-items: center; /* 垂直置中 */
  justify-content: flex-start; /* 控制子元素之間的間距 */
  gap: 10px; /* 子元素之間的間距 */
  flex-direction: row;
}
</style>
