<template>
  <div v-loading="isLoading">
    <p>{{ audioPermissionStatus }}</p>
    <div  v-if="isAudioPermission">
      <div v-for="(area, index) in areaBlocks" :key="index">
        <area-block :area="area" :use_chart_btn="true" :alert="alert" :close_alert="close_alert">
        </area-block>
      </div>
    </div>
    <div v-else> <el-button @click="openOCC()">啟用OCC頁面</el-button></div>
    <audio src="../assets/Alarm.wav" id="audioElement"></audio>
  </div>
</template>

<script>
//import { dotList, getStatusColor } from '@/lib/base/systemAlert';
import AreaBlock from '../components/occ/AreaBlock.vue';

export default {
  components: {
    AreaBlock,
  },
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      project:null,
      autoRefresh: false,
      isLoading: true,
      areaBlocks: [],
      use_chart_btn: false,
      audioPermissionStatus:'',
      isAudioPermission: false,

      alertFlag: false,
      alertList: [],
    };
  },
  computed: {
    timezone() {
      return this.$store.getters['env/timezone'];
    },
    selectedProject() {
      if(this.project) return this.project;
      return this.$store.getters['project/selectedProject'];
    },
    isNotOCCUser() {
      return this.$store.getters['user/details'].roles != 'occ';
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshFunction);
  },
  mounted() {
    if(this.useUrl){
      //console.log('using url!');
      this.setProject()
        .then(() => {
          this.init();
        });
    }
    else{
      //console.log('didnt use url!');
      this.init();
    }
  },
  methods: {
    async requestLocationPermission() {
      this.audioPermissionStatus = '音訊權限未授予';
      this.isAudioPermission = false;

      try{
        // 创建一个 AudioContext 对象
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        // 尝试创建静默音频
        const buffer = audioContext.createBuffer(1, 1, 22050);
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);

        // 尝试播放静默音频（实际上不会播放声音）
        source.start(0);

        // 处理音频上下文的状态
        audioContext.resume().then(() => {
          console.log('音频权限已授予');
          this.audioPermissionStatus = '音訊權限已授予';
          this.isAudioPermission = true;
        });
      }
      catch(error){
        console.error('無法獲取音頻權限', error);
        this.audioPermissionStatus = '音訊權限未授予';
      }
    },
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    init() {
      this.isLoading = true;
      this.getInstrumentsAndAreas().then(()=>{
        this.isLoading = false;
        this.requestLocationPermission();
      });
    },
    async getInstrumentsAndAreas() {
      //project/:projectId/data/last/:timestamp
      let res = await this.$axios({
        category: 'occ',
        method: 'get',
        apiName: `${this.selectedProject._id}/getAllAreas`,
      });
      this.areaBlocks = res.data;
      this.areaBlocks = this.areaBlocks.map(ele=>{
        return {
          ...ele,
          no: this.getNoByArea(ele),
        };
      });
      //this.areaBlocks.forEach(ele => {
      //  console.log(ele.name);
      //});
    },
    getNoByArea(area){
      console.log(area.name);
      let X = area.name.split('+')[0];
      let Y = area.name.split('+')[1];
      if(X == 'TK28') return '1602LS';
      if(X == 'TK86') return '2615LS';
      if(X == 'TK88') return '2633LS';
      if(X == 'TK93' && Y == '151') return '2634LS';
      else if(X == 'TK93' && Y == '500') return '2616LS';
      //else if(X == 'TK93' && '889') return '2620LS';
      if(X == 'TK96') return '2620LS';
      if(X == 'TK98') return '2622LS';
      if(X == 'TK99') return '2625LS';
      if(X == 'TK100') return '2626LS(W),2627LS(E)';
      if(X == 'TK107') return '3101RF';
      if(X == 'TK117') return '3104LS';
      if(X == 'TK118') return '3106LS(N),3107LS(S)';
      if(X == 'TK123') return '3121LS';
      if(X == 'TK125') return '3114LS';
      if(X == 'TK126') return '3115LS';
      if(X == 'TK171') return '4101LS';
      if(X == 'TK172') return '4103LS';
      return '';
    },
    async alert(instrumentName){
      //console.log(text);
      if(!this.isAudioPermission){
        console.log('無法獲取音頻權限');
      }
      
      const alertFlag = this.alertList.includes(instrumentName);

      if(alertFlag) return;

      this.alertList.push(instrumentName);
      //alert(text);
      
      console.log(`play by ${instrumentName}`);
      try{
        // 创建一个音频元素并设置音频源
        const audio = document.getElementById('audioElement');
        audio.loop = true;
        audio.load();
        // 等待音频加载
        await audio.play();
        // 如果播放成功，说明用户已经允许了音频权限
        console.log('音频播放成功，权限已获得');
        this.permissionStatus = '音訊權限已授予';
      } catch (error) {
        // 播放失败，用户可能拒绝了音频权限
        console.error('音频播放失败，可能是因为权限被拒绝', error);
        this.permissionStatus = '音訊權限未授予';
      }
      
    },
    close_alert(instrumentName){
      this.alertList = this.alertList.filter(insName => insName !== instrumentName);

      if(this.alertList.length > 0) return;

      console.log(`pause by ${instrumentName}`);
      const audio = document.getElementById('audioElement');
      audio.pause(); // 暂停音频播放
      audio.currentTime = 0; // 将播放位置重置为开头
    },
    openOCC(){
      this.requestLocationPermission();
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
