import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numberFormats from './numberFormats';
import ElementUiZhTwLocale from 'element-ui/lib/locale/lang/zh-TW';
import ElementUiEnLocale from 'element-ui/lib/locale/lang/en';

import zhTW from './lang/zh';
import enUS from './lang/en';

Vue.use(VueI18n);

let i18n;

export function createI18n(lang) {
  i18n = new VueI18n({
    locale: lang || 'zh-TW',  // 如果沒有指定語言，使用 zh-TW 作為默認值
    messages: {
      'zh-TW': {
        ...zhTW,
        ...ElementUiZhTwLocale,
      },
      'en-US': {
        ...enUS,
        ...ElementUiEnLocale,
      },
    },
    numberFormats,
  });
  return i18n;
}

export { i18n };