<template>
  <el-form
    ref="areaForm"
    :model="form"
    :validate-on-rule-change="false"
    :hide-required-asterisk="true"
    :inline="true"
    label-position="top"
    label-width="80px"
  >
    <el-row>
      <el-form-item
        prop="project"
        label="專案"
      >
        <el-select
          v-model="form.project"
          @change="setProjectArea"
        >
          <el-option
            v-for="project in projectList"
            :key="project._id"
            :value="project._id"
            :label="project.project_id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item
        prop="area"
        label="區域"
      >
        <el-drag-select
          v-model="form.area"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="輸入並Enter新增"
        >
          <el-option
            v-for="area in areaList"
            :key="area._id"
            :label="area.name"
            :value="area._id"
          />
        </el-drag-select>
      </el-form-item>
    </el-row>
    <el-button
      @click="submitForm"
      type="primary"
    >
      更新區域
    </el-button>
    <el-button
      @click="updateSelectSort"
      type="primary"
    >
      更新排序
    </el-button>
  </el-form>
</template>

<script>
export default {
  props: {
    projectId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      form: {
        project: '',
        area: [],
      },
      areaList: [],
    };
  },
  computed: {
    selectedProject() {
      const selectedProjectId = this.form.project;
      const selectedProject = this.projectList.find(ele => ele._id === selectedProjectId);
      return selectedProject;
    },
    areaIdList() {
      return this.areaList.map(area => area._id);
    },
    projectList() {
      return this.$store.getters['project/projectList'];
    },
  },
  watch: {
    projectId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form.project = newValue;
      }
    },
    'form.project'(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue !== oldValue) {
        this.form.area = [];
        this.setProjectArea();
      }
    },
  },
  async mounted() {
    if (this.projectId) {
      this.form.project = this.projectId;
    } else {
      this.form.project = this.$store.state.project.selectedProject._id;
    }
    await this.getAreaList();
    await this.setProjectArea();
  },
  methods: {
    setProjectArea() {
      this.form.area = this.selectedProject.area;
      console.log('setProjectArea -> this.selectedProject.area', this.selectedProject.area);
    },
    getAreaList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getAreaList', isUpdate)
        .then(res => {
          this.areaList = res;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateSelectSort() {
      let message = '此操作將改變區域排序';
      const response = await this.$confirmCreate(message);
      if (response === 'confirm') {
        this.isLoading = true;
        let area = this.form.area;
        if (!area.length) {
          this.$notifyError('區域列表不能為空');
          this.isLoading = false;
          return;
        }

        try {
          await this.removeAllAreaFromProject();
          await this.addAreaToProject(area);

          this.$notifySuccess('修改成功');
          await this.getAreaList(true);
          await this.$store.dispatch('project/getProjectList', true);
        } catch (error) {
          console.log(error);
          this.$notifyError(error);
        } finally {
          this.isLoading = false;
          // window.location.reload();
        }
      }
    },
    async removeAllAreaFromProject() {
      return this.$axios({
        category: 'admin',
        method: 'delete',
        apiName: `project/${this.form.project}/allarea`,
        data: {
          area: [],
        },
      }).catch(this.$notifyError);
    },
    submitForm() {
      this.$refs.areaForm.validate(async valid => {
        if (valid) {
          this.updateProjectArea();
        } else {
          return false;
        }
      });
    },
    async addAreaToProject(areaIdList) {
      if (!areaIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: `project/${this.form.project}/area`,
        data: {
          area: areaIdList,
        },
      }).catch(this.$notifyError);
    },
    async removeAreaFromProject(areaIdList) {
      if (!areaIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'delete',
        apiName: `project/${this.form.project}/area`,
        data: {
          area: areaIdList,
        },
      }).catch(this.$notifyError);
    },
    async createProjectNewArea(areaIdList) {
      if (!areaIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: 'area',
        data: {
          project: this.form.project,
          area: areaIdList,
        },
      }).catch(this.$notifyError);
    },
    async updateProjectArea() {
      const removedArea = this.selectedProject.area.filter(area => {
        return !this.form.area.includes(area);
      });
      const newAddedArea = this.form.area.filter(area => {
        return !this.areaIdList.includes(area);
      });

      const pushedArea = this.form.area.filter(area => {
        return !this.selectedProject.area.includes(area) && this.areaIdList.includes(area);
      });

      if (!newAddedArea.length && !removedArea.length && !pushedArea.length) {
        return this.$notifyInfo('沒有改動');
      }

      let message = '此操作將';
      if (newAddedArea.length) {
        message += `新增 ${newAddedArea.length} 個區域並加至該專案\n`;
      }
      if (removedArea.length) {
        message += `從該專案移除 ${removedArea.length} 個區域\n`;
      }
      if (pushedArea.length) {
        message += `更新 ${pushedArea.length} 個區域至該專案\n`;
      }
      const response = await this.$confirmCreate(message);
      if (response === 'confirm') {
        this.isLoading = true;
        Promise.all([
          this.createProjectNewArea(newAddedArea),
          this.removeAreaFromProject(removedArea),
          this.addAreaToProject(pushedArea),
        ])
          .then(() => {
            this.$notifySuccess('新增成功');
            this.getAreaList(true);
            return this.$store.dispatch('project/getProjectList', true);
          })
          .catch(error => {
            console.log(error);
            this.$notifyError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
