<template>
  <el-select ref="elDragSelect" v-model="selectVal" v-bind="$attrs" class="drag-select" multiple v-on="$listeners">
    <slot/>
  </el-select>
</template>

<script>
import Sortable from 'sortablejs';

export default {
  name: 'elDragSelect',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectVal: {
      get() {
        return [...this.value];
      },
      set(val) {
        this.$emit('input', [...val]);
      },
    },
  },
  mounted() {
    this.setSort();
  },
  methods: {
    setSort() {
      const el = this.$refs.elDragSelect.$el.querySelectorAll('.el-select__tags > span')[0];
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData (dataTransfer) {
          dataTransfer.setData('Text', '');
        },
        onEnd: evt => {
          const targetRow = this.value.splice(evt.oldIndex, 1)[0];
          this.value.splice(evt.newIndex, 0, targetRow);
        },
      });
    },
  },
};
</script>

<style scoped>
.drag-select ::v-deep .sortable-ghost {
  opacity: .8;
  color: #fff !important;
  background: #42b983 !important;
}

.drag-select ::v-deep .el-tag {
  cursor: pointer;
}
</style>
